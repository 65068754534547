import { SHOW_COOKIE_CONSENT_POP_UP } from '../actions/types'

const areCookiesManaged = (dispatch, isCookieBannerEnabled) => {
  if (isCookieBannerEnabled === false) {
    return false
  }

  const cookiesStatus = localStorage.getItem('cookieConsent')

  if (!cookiesStatus) {
    dispatch({ type: SHOW_COOKIE_CONSENT_POP_UP, payload: { showCookiePopup: true } })
    return false
  }

  return true
}

export default areCookiesManaged
