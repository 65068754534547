import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Languages from '../Enum/Languages'

const HelmetTag = ({ pageTitle = undefined }) => {
  const lang = useSelector((state) => state.lang.lang)
  return (
    <Helmet>
      <title>
        {pageTitle ? `${pageTitle} - Sami Swoi` : 'Sami Swoi - Przekazy pieniężne do Polski z UK i strefy Euro'}
      </title>
      <link rel="canonical" hrefLang={lang === Languages.en_US ? 'en' : 'pl'} href={window.location.href} />
    </Helmet>
  )
}

HelmetTag.propTypes = {
  pageTitle: PropTypes.string
}

export default HelmetTag
