import {
  DELETE_REPEAT_PAYMENT,
  DELETE_SINGLE_SCHEDULE,
  DELETE_SINGLE_SCHEDULE_FAILURE,
  DELETE_SINGLE_SCHEDULE_REQUEST,
  ENTER_RPI,
  GET_LIST_OF_REPEAT_PAYMENTS,
  GET_PERIODS,
  GET_REPEAT_PAYMENT,
  GET_RP_SURVEY,
  GET_RPI_SCHEDULE,
  GET_RPI_SCHEDULE_FAILURE,
  GET_RPI_SCHEDULE_REQUEST,
  GO_TO_EDIT_RPI,
  POST_RP_SURVEY,
  REQUEST_NEW_CARD,
  SET_RPI_CALCULATOR_DATA,
  RESET_APP_DATA
} from '../actions/types'

const initialState = {
  repeat_payments: [],
  last: 0,
  loadingShedule: true,
  scheduleDeleting: false,
  requestNewCard: false
}

export default (state = initialState, action = { type: undefined, payload: undefined }) => {
  switch (action.type) {
    case GET_PERIODS:
      return {
        ...state,
        periods: action.payload
      }

    case GET_LIST_OF_REPEAT_PAYMENTS:
      return {
        ...state,
        repeat_payments: action.payload.data.attributes,
        last: action.payload.links?.last?.match(/\d+/)[0],
        deleted: false
      }

    case GET_REPEAT_PAYMENT:
      return {
        ...state,
        repeat_payment: action.payload,
        loadingShedule: true
      }

    case DELETE_REPEAT_PAYMENT:
      return {
        ...state,
        repeat_payments: state.repeat_payments?.map((payment) => {
          if (action.payload.repeatId !== payment.id) return payment
          return { ...payment, status: 2 }
        }),
        deleted: true
      }

    case GO_TO_EDIT_RPI:
      return {
        ...state,
        rpiToEdit: { repeatPayment: action.payload.rpi }
      }

    case ENTER_RPI:
      return {
        ...state,
        rpiToEdit: undefined
      }

    case GET_RP_SURVEY:
      return {
        ...state,
        survey: action.payload.data
      }

    case POST_RP_SURVEY:
      return {
        ...state,
        survey_success: true
      }

    case GET_RPI_SCHEDULE_REQUEST:
      return {
        ...state,
        loadingShedule: true
      }

    case GET_RPI_SCHEDULE_FAILURE:
      return {
        ...state,
        loadingShedule: false,
        rpiSchedule: []
      }

    case GET_RPI_SCHEDULE:
      return {
        ...state,
        loadingShedule: false,
        rpiSchedule: action.payload
      }

    case DELETE_SINGLE_SCHEDULE_REQUEST:
      return {
        ...state,
        scheduleDeleting: true
      }

    case DELETE_SINGLE_SCHEDULE_FAILURE:
      return {
        ...state,
        scheduleDeleting: false
      }

    case DELETE_SINGLE_SCHEDULE:
      return {
        ...state,
        repeat_payments: state.repeat_payments.filter((rpi) => rpi.id !== action.payload),
        scheduleDeleting: false
      }

    case REQUEST_NEW_CARD:
      return {
        ...state,
        requestNewCard: action.payload
      }

    case SET_RPI_CALCULATOR_DATA:
      return {
        ...state,
        calculatorData: action.payload
      }

    case RESET_APP_DATA:
      return null

    default:
      return {
        ...state
      }
  }
}
