export default {
  BENEFICIARY_FORM: 'BENEFICIARY_FORM',
  EWBENEFICIARY_FORM: 'EWBENEFICIARY_FORM',
  BENEFICIARY_CONFIRM_FORM: 'BENEFICIARY_CONFIRM_FORM',
  CARD_FORM: 'CARD_FORM',
  CARD_SECURE_FORM: 'CARD_SECURE_FORM',
  SURVEY_MODAL: 'SURVEY_MODAL',
  TRACK_MODAL: 'TRACK_MODAL',
  PASSWORD_MODAL: 'PASSWORD_MODAL',
  PROMOCODE_MODAL: 'PROMOCODE_MODAL',
  LOGIN_AUTH_MODAL: 'LOGIN_AUTH_MODAL',
  FRAUD_AWARENESS_MODAL: 'FRAUD_AWARENESS_MODAL',
  FINANCIAL_MODAL: 'FINANCIAL_MODAL',
  ID_SCAN_ACKNOWLEDGMENT_MODAL: 'ID_SCAN_ACKNOWLEDGMENT_MODAL'
}
