import { Container, Row, Col } from 'react-bootstrap'
import '../../styles/components/layout/CmsLoaderStyle.scss'
import Logo from '../../images/logo.webp'

const MaintenanceScreen = () => (
  <div className="cms-loader pt-2 pb-4">
    <div className="wrapper">
      <Container className="maintenance-container">
        <div className="text-center mb-4"><img src={Logo} alt="" className="img-fluid maintenance-logo" /></div>
        <Row>
          <Col xs={12} lg={8} xl={9}>
            <h1 className="text-muted mb-3 h3">
              We are sorry! The website is currently undergoing maintenance.
            </h1>
            <h2 className="fw-bold text-primary mb-4 h1">The website will be available again shortly.</h2>
            <h2 className="fw-normal text-muted-light h6">
              We&apos;re busy upgrading it to elevate our user experience using cutting-edge technology to continually
              enhance our services. We apologize for any inconvenience this may cause to you.
            </h2>
            <hr className="my-5" />
            <h2 className="text-muted mb-3 h3">
              Przepraszamy! Strona jest obecnie w trakcie prac konserwacyjnych.
            </h2>
            <h2 className="fw-bold text-primary mb-4 h1">Strona będzie wkrótce dostępna ponownie.</h2>
            <h2 className="fw-normal text-muted-light h6">
              Obecnie prowadzimy prace modernizacyjne, których celem jest podniesienie jakości oferowanych przez nas
              usług oraz poprawa doświadczenia użytkownika, dzięki zastosowaniu najnowszych technologii. Przepraszamy
              za wszelkie niedogodności.
            </h2>
          </Col>
          <Col xs={12} lg={4} xl={3} className="ps-0">
            <div className="maintenance-icon">
              <span className="material-icons spin gear-one">settings</span>
              <span className="material-icons spin gear-two spin-back">settings</span>
              <span className="material-icons spin gear-three">settings</span>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </div>
)
export default MaintenanceScreen
