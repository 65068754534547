import { GET_ACTIVE_VERIFICATION, POST_CARD_VERIFICATION } from '../actions/types'

const initialState = {
  cardVerificationPatchSuccess: false
}

export default (state = initialState, action = { type: undefined, payload: undefined }) => {
  switch (action.type) {
    case GET_ACTIVE_VERIFICATION:
      return {
        ...state,
        cardVerification: action.payload
      }

    case POST_CARD_VERIFICATION:
      return {
        ...state,
        cardVerificationPost: action.payload
      }

    default:
      return state
  }
}
