import { useSelector } from 'react-redux'
import Logo from '../../images/logo.webp'
import CmsLoader from './CmsLoader'

const Loader = () => {
  const cmsErrorsCounter = useSelector((state) => state.cms?.cmsErrorsCounter) || 0
  if (cmsErrorsCounter > 2) {
    return (
      <CmsLoader />
    )
  }
  return (
    <div className="full-page-loader bg-light w-100 h-100">
      <img width="200" height="auto" src={Logo} alt="Loading..." />
    </div>
  )
}

export default Loader
