import { GET_FIELD_VALUES, RESET_APP_DATA } from '../actions/types'

const initialState = {
  fieldValues: null,
  fieldValuesLoading: true
}

export default (state = initialState, action = { type: undefined, payload: undefined }) => {
  switch (action.type) {
    case GET_FIELD_VALUES:
      return {
        ...state,
        fieldValues: action.payload,
        fieldValuesLoading: false
      }

    case RESET_APP_DATA:
      return {}

    default:
      return state
  }
}
