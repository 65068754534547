const config = {
  url: process.env.REACT_APP_CMS_HOST,
  path: {
    aboutRepeatPayments: 'about-repeat-payment',
    aboutUsPage: 'about-us',
    addressPicker: 'address-picker',
    beneficiaryPage: 'beneficiary-page',
    branchesPage: 'branche',
    calculator: 'calculator',
    cardList: 'card-list',
    contactUsPage: 'contact',
    customerDetail: 'customer-detail',
    exchangeAlertPage: 'exchange-alert-page',
    extraServices: 'extra-services-page',
    faqPage: 'faq',
    feedbackPage: 'opinion',
    footer: 'footer',
    getProducts: 'products',
    getTransferType: 'transfer-type-info',
    notFoundPage: 'not-found-page',
    splashScreen: 'splash-screen',
    historyPage: 'history-page',
    landingPage: 'landing-page',
    loginPage: 'login-page',
    logoutPage: 'logout-page',
    mobileApplicationPage: 'transfer-mobile',
    navbar: 'navbar',
    opinions: 'reviews',
    registerPage: 'register-page',
    repeatPaymentPage: 'repeat-payment-page',
    safeTransfersCard: 'safeTransfersCard',
    sendMoney: 'sendMoney',
    setPasswordPage: 'set-password-page',
    trackingPage: 'tracking-page',
    trackModal: 'track-modal',
    transferCurrency: 'transfer-currency',
    transferExpressPage: 'transfer-express',
    transferForNowPage: 'transfer-for-now',
    complaintsPage: 'complaints-page',
    transferPage: 'transfer-page',
    transfers: 'transfers',
    transferStandardPage: 'transfer-standard',
    typesOfTransferPage: 'transfers-types-page',
    transferUk: 'transfer-uk',
    uploadPage: 'upload-page',
    viaInternet: 'via-internet',
    viaPhoneline: 'via-phoneline',
    widgets: 'widget',
    confirmAccountPage: 'confirm-account-page',
    opinionComponent: 'opinion-widget',
    beneficiaryList: 'beneficiary',
    paymentCardList: 'payment-cards-list',
    transferTypePage: 'transfer-types-page',
    accountFunctionality: 'account-functionality',
    tourComponent: 'tour-guide',
    fraudAwareness: 'fraud-awareness-page',
    financialModal: 'financial-modal',
    privacyPage: 'privacy-page',
    deleteAccountPage: 'delete-account-page',
    cookiesPage: 'cookies-page',
    cookiesConsentPopUps: 'cookies-consent-pop-up',
    faqWidget: 'faq-widget',
    termsPage: 'terms-conditions-page',
    b2bPage: 'b2b-page',
    transferAlert: 'transfer-alert',
    ltfForm: 'ltf-form',
    paymentMethods: 'payment-method'
  }
}

export default config
