import { GET_AGENTS } from '../actions/types'

const initialState = {}

export default (state = initialState, action = { type: undefined, payload: undefined }) => {
  switch (action.type) {
    case GET_AGENTS:
      return {
        ...state,
        agents: action.payload.data.attributes,
        agentsLinks: action.payload.links
      }

    default:
      return {
        ...state
      }
  }
}
