import { GET_SURVEY_QUESTION, RESET_APP_DATA } from '../actions/types'

const initialState = {
  question: null,
  questionId: null,
  loading: true
}

export default (state = initialState, action = { type: undefined, payload: undefined }) => {
  switch (action.type) {
    case GET_SURVEY_QUESTION:
      return {
        ...state,
        question: action.payload.question,
        questionId: action.payload.questionId,
        loading: false
      }

    case RESET_APP_DATA:
      return {}

    default:
      return state 
  }
}
