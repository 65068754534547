import * as OneMoneyMailMobileApi from 'omm-rest-api-client'
import axiosInstance, { apiUrl } from '../config/api'
import { SET_LANGUAGE, GET_ERRORS } from './types'
import Languages from '../Enum/Languages'

const apiInstance = new OneMoneyMailMobileApi.CustomerAccountApi({}, apiUrl, axiosInstance)

export const setLanguage = (targetLanguage) => (dispatch, getState) => {
  const customerLang = getState()?.customerAccount?.customer?.attributes?.customerDetail?.language;
  const customerId = getState()?.login?.customer_id;
  const validatedLanguage = Object.values(Languages).includes(targetLanguage) ? targetLanguage : Languages.default;

  localStorage.setItem('lang', validatedLanguage);

  dispatch({
    type: SET_LANGUAGE,
    payload: { lang: validatedLanguage }
  });

  if (customerId && validatedLanguage !== customerLang) {
    apiInstance
      .customersCustomerIdLanguagePost(customerId, {
        data: {
          type: 'customers',
          attributes: {
            language: validatedLanguage
          }
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: { customerAccount: err.response?.data }
        });
      });
  }
}

export default setLanguage;
