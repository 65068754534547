import { SET_LOCATION } from './types'

export const dispatchSetLocation = (pathName, language, dispatch) => {
  dispatch({
    type: SET_LOCATION,
    payload: {
      pathName,
      language
    }
  })
}
export default dispatchSetLocation
