import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Accordion, ToggleButtonGroup, ToggleButton, Form } from 'react-bootstrap'
import '../../../styles/components/Modal/CookieSettingsModalStyle.scss'
import { useSelector } from 'react-redux'
import Markdown from '../../Markdown'

const CookieSettingsModal = ({ showCookiesModal, handleAccept, handleReject }) => {
  const [contentDisplayed, setContentDisplayed] = useState('settings')
  const [switchStates, setSwitchStates] = useState([])
  const popUpContent = useSelector((state) => state.cms?.cookiesConsentPopUps?.cookiesSettingsPopUp)
  const cookiesPageLink = useSelector((state) => state.cms?.cookiesConsentPopUps?.cookiesPolicyLink)

  useEffect(() => {
    if (popUpContent?.cookiesSettingsAccordions) {
      const initialStates = popUpContent.cookiesSettingsAccordions.map((_, index) => index === 2)
      setSwitchStates(initialStates)
    }
  }, [popUpContent])

  const handleSwitchChange = (index, checked) => {
    const newStates = [...switchStates]
    newStates[index] = checked
    setSwitchStates(newStates)
  }

  if (!showCookiesModal) return null

  const aboutCookiesContent = (
    <div className="cookie-settings-body">
      <Accordion defaultActiveKey="0" className="cookie-about-accordion">
        {popUpContent?.aboutCookiesAccordions.map((accordion, index) => (
          <Accordion.Item eventKey={`${index}`} key={accordion.title}>
            <Accordion.Header>{accordion.title}</Accordion.Header>
            <Accordion.Body>
              <Markdown>{accordion.description}</Markdown>
              {index === 0 && (
                <span>
                  {cookiesPageLink.linkDescription}
                  <a href={cookiesPageLink.linkAddress}>{cookiesPageLink.linkText}</a>
                </span>
              )}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  )

  const settingsContent = (
    <div className="cookie-settings-body">
      <p>{popUpContent?.settingsDescription}</p>
      <Accordion defaultActiveKey="0" className="cookie-settings-accordion">
        {popUpContent?.cookiesSettingsAccordions.map((accordion, index) => (
          <Accordion.Item eventKey={`${index}`} key={accordion.title}>
            <div className="d-flex justify-content-between align-items-center">
              <Accordion.Header>{accordion.title}</Accordion.Header>
              <Form.Check
                type="switch"
                id={`${accordion.title}-cookies-switch`}
                label=""
                className="me-3"
                checked={switchStates[index]}
                onChange={(e) => handleSwitchChange(index, e.target.checked)}
                disabled={index === 2}
              />
            </div>
            <Accordion.Body>
              <Markdown>{accordion.description}</Markdown>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  )

  return (
    <div className="cookie-settings-backdrop">
      <div className="cookie-settings-modal">
        <ToggleButtonGroup type="radio" name="options" defaultValue="settings" className="cookie-settings-toggle-group">
          <ToggleButton
            id="tbg-radio-1"
            value="settings"
            className="toggle-button"
            onClick={() => setContentDisplayed('settings')}
          >
            {popUpContent.cookiesSettingsTitle}
          </ToggleButton>
          <ToggleButton
            id="tbg-radio-2"
            value="about"
            className="toggle-button"
            onClick={() => setContentDisplayed('about')}
          >
            {popUpContent.aboutCookiesTitle}
          </ToggleButton>
        </ToggleButtonGroup>
        {contentDisplayed === 'settings' ? settingsContent : aboutCookiesContent}
        {contentDisplayed === 'settings' && (
          <div className="cookie-settings-footer">
            <button type="button" className="btn-reject" onClick={handleReject}>{popUpContent.rejectButton}</button>
            <button type="button" className="btn-reject" onClick={handleAccept}>{popUpContent.saveButton}</button>
            <button type="button" className="btn-accept" onClick={handleAccept}>{popUpContent.acceptButton}</button>
          </div>
        )}
      </div>
    </div>
  )
}

CookieSettingsModal.propTypes = {
  showCookiesModal: PropTypes.bool.isRequired,
  handleAccept: PropTypes.func.isRequired,
  handleReject: PropTypes.func.isRequired
}

export default CookieSettingsModal
