import React from 'react'
import '../../../styles/components/Modal/SimpleCookieConsentModalStyle.scss'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from '../../../utils/isEmpty'
import { getCookiesConsentPopUpsContent } from '../../../actions/cms'
import useLang from '../../../utils/hooks/useLang'

const SimpleCookieConsentModal = ({
  showCookiesModal = false,
  handleManage,
  handleAccept,
  handleReject
}) => {
  const dispatch = useDispatch()
  const { lang, prevLang } = useLang()
  const popUpContent = useSelector((state) => state.cms?.cookiesConsentPopUps)

  if (isEmpty(popUpContent) || prevLang !== lang) {
    dispatch(getCookiesConsentPopUpsContent())
  }

  if (!showCookiesModal) return null

  return (
    <div className="cookie-consent-modal-backdrop">
      <div className="cookie-consent-modal">
        <div className="cookie-consent-modal-header">
          <h1 className="cookie-consent-modal-title h5">{popUpContent?.simpleCookiesPopUp.title}</h1>
        </div>
        <div className="cookie-consent-modal-body">
          <div className="cookie-consent-modal-actions">
            <p>{popUpContent?.simpleCookiesPopUp.description}
              <a href={popUpContent?.cookiesPolicyLink.linkAddress}>{popUpContent?.cookiesPolicyLink.linkText}</a>
            </p>
            <div className="cookie-consent-modal-actions-buttons">
              <button type="button" className="btn-manage" onClick={handleManage}>
                {popUpContent?.simpleCookiesPopUp.manageButton}
              </button>
              <button type="button" className="btn-reject" onClick={handleReject}>
                {popUpContent?.simpleCookiesPopUp.rejectButton}
              </button>
              <button type="button" className="btn-accept" onClick={handleAccept}>
                {popUpContent?.simpleCookiesPopUp.acceptButton}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

SimpleCookieConsentModal.propTypes = {
  showCookiesModal: PropTypes.bool,
  handleAccept: PropTypes.func.isRequired,
  handleReject: PropTypes.func.isRequired,
  handleManage: PropTypes.func.isRequired
}

export default SimpleCookieConsentModal
