import Languages from '../Enum/Languages'
import Pages from '../Enum/Pages'

const paths = {
  [Pages.index]: {
    [Languages.pl_PL]: '/',
    [Languages.en_US]: '/'
  },
  [Pages.loginPage]: {
    [Languages.pl_PL]: '/logowanie',
    [Languages.en_US]: '/login'
  },
  [Pages.logoutPage]: {
    [Languages.pl_PL]: '/sesja-zakonczona',
    [Languages.en_US]: '/logged-out'
  },
  [Pages.registerPage]: {
    [Languages.pl_PL]: '/rejestracja',
    [Languages.en_US]: '/signup'
  },
  [Pages.branchesPage]: {
    [Languages.pl_PL]: '/oddzialy-samiswoi',
    [Languages.en_US]: '/samiswoi-branches'
  },
  [Pages.agentsPage]: {
    [Languages.pl_PL]: '/agenci-samiswoi',
    [Languages.en_US]: '/agents'
  },
  [Pages.extraServicesPage]: {
    [Languages.pl_PL]: '/oddzialy-samiswoi-uslugi',
    [Languages.en_US]: '/branches-samiswoi-services'
  },
  [Pages.aboutUsPage]: {
    [Languages.pl_PL]: '/o-nas',
    [Languages.en_US]: '/about-us'
  },
  [Pages.contactUsPage]: {
    [Languages.pl_PL]: '/kontakt',
    [Languages.en_US]: '/contact-us'
  },
  [Pages.feedbackPage]: {
    [Languages.pl_PL]: '/opinie',
    [Languages.en_US]: '/opinions'
  },
  [Pages.transferStandardPage]: {
    [Languages.pl_PL]: '/transfer-standardowy',
    [Languages.en_US]: '/transfer-standard'
  },
  [Pages.transferExpressPage]: {
    [Languages.pl_PL]: '/transfer-ekspresowy',
    [Languages.en_US]: '/transfer-express'
  },
  [Pages.transferTenMinPage]: {
    [Languages.pl_PL]: '/transfer-w-10-minut',
    [Languages.en_US]: '/transfer-in-10-minutes'
  },
  [Pages.complaintsPage]: {
    [Languages.pl_PL]: '/reklamacje',
    [Languages.en_US]: '/complaints'
  },
  [Pages.mobileApplicationPage]: {
    [Languages.pl_PL]: '/przekazy-mobilne',
    [Languages.en_US]: '/mobile'
  },
  [Pages.viaInternetPage]: {
    [Languages.pl_PL]: '/przez-internet',
    [Languages.en_US]: '/via-internet'
  },
  [Pages.viaPhonelinePage]: {
    [Languages.pl_PL]: '/przez-telefon',
    [Languages.en_US]: '/via-phoneline'
  },
  [Pages.transferCurrencyPage]: {
    [Languages.pl_PL]: '/transfer-walutowy',
    [Languages.en_US]: '/transfer-currency'
  },
  [Pages.aboutRepeatPayments]: {
    [Languages.pl_PL]: '/zlecenia-stale',
    [Languages.en_US]: '/repeat-payments'
  },
  [Pages.confirmAccountPage]: {
    [Languages.pl_PL]: '/rejestracja/aktywuj-konto/:token',
    [Languages.en_US]: '/signup/activate-account/:token'
  },
  [Pages.resetPassword]: {
    [Languages.pl_PL]: '/zmien-haslo/:token',
    [Languages.en_US]: '/change-password/:token'
  },
  [Pages.faqPage]: {
    [Languages.pl_PL]: '/dobrze-wiedziec',
    [Languages.en_US]: '/faq'
  },
  [Pages.charityPage]: {
    [Languages.pl_PL]: '/charityPage',
    [Languages.en_US]: '/charityPage'
  },
  [Pages.transferUk]: {
    [Languages.pl_PL]: '/przelewy-z-uk',
    [Languages.en_US]: '/money-transfer-from-uk'
  },
  [Pages.transferPage]: {
    [Languages.pl_PL]: '/system/wykonaj-przekaz',
    [Languages.en_US]: '/system/transfer-money'
  },
  [Pages.historyPage]: {
    [Languages.pl_PL]: '/system/historia-transakcji',
    [Languages.en_US]: '/system/transaction-history'
  },
  [Pages.customerPage]: {
    [Languages.pl_PL]: '/system/dane-klienta',
    [Languages.en_US]: '/system/customer-details'
  },
  [Pages.uploadPage]: {
    [Languages.pl_PL]: '/system/przeslij-inne-dokumenty',
    [Languages.en_US]: '/system/upload-additional-documents'
  },
  [Pages.idScanReDirect]: {
    [Languages.pl_PL]: '/verify-your-identity/re-direct',
    [Languages.en_US]: '/verify-your-identity/re-direct'
  },
  [Pages.verificationPage]: {
    [Languages.pl_PL]: '/system/zweryfikuj-swoja-tozsamosc',
    [Languages.en_US]: '/system/verify-your-identity'
  },
  [Pages.idScanPage]: {
    [Languages.pl_PL]: '/system/zweryfikuj-swoja-tozsamosc/skan',
    [Languages.en_US]: '/system/verify-your-identity/scan'
  },
  [Pages.beneficiaryListPage]: {
    [Languages.pl_PL]: '/system/lista-odbiorcow',
    [Languages.en_US]: '/system/beneficiary-list'
  },
  [Pages.rpiAddPage]: {
    [Languages.pl_PL]: '/system/zlecenie-stale',
    [Languages.en_US]: '/system/repeat-payment'
  },
  [Pages.repeatPayments]: {
    [Languages.pl_PL]: '/system/lista-zlecen-stalych',
    [Languages.en_US]: '/system/repeat-payments-list'
  },
  [Pages.trackingPage]: {
    [Languages.pl_PL]: '/tracking',
    [Languages.en_US]: '/transfers-tracking'
  },
  [Pages.notFound]: {
    [Languages.pl_PL]: '/page-not-found',
    [Languages.en_US]: '/page-not-found'
  },
  [Pages.fraudAwarenessPage]: {
    [Languages.pl_PL]: '/oszustwa-internetowe',
    [Languages.en_US]: '/fraud-awareness'
  },
  [Pages.privacyPage]: {
    [Languages.pl_PL]: '/polityka-prywatnosci',
    [Languages.en_US]: '/privacy-policy'
  },
  [Pages.accountDeletePage]: {
    [Languages.pl_PL]: '/system/usuniecie-konta',
    [Languages.en_US]: '/system/account-deletion'
  },
  [Pages.cookiesPage]: {
    [Languages.pl_PL]: '/polityka-cookies',
    [Languages.en_US]: '/cookies-policy'
  },
  [Pages.typesOfTransferPage]: {
    [Languages.pl_PL]: '/rodzaje-transferow',
    [Languages.en_US]: '/types-of-transfers'
  },
  [Pages.termsPage]: {
    [Languages.pl_PL]: '/regulamin-swiadczenia-uslug',
    [Languages.en_US]: '/terms-and-conditions'
  },
  [Pages.forBusinessPage]: {
    [Languages.pl_PL]: '/dla-biznesu',
    [Languages.en_US]: '/for-business'
  },
  [Pages.globalPaymentRedirect]: {
    [Languages.pl_PL]: '/global-payment-redirect',
    [Languages.en_US]: '/global-payment-redirect'
  }
}

export default paths
