import './App.scss'
import React, { Suspense, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ReactNotifications } from 'react-notifications-component'
import { HelmetProvider } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import Loader from './components/Loader/Loader'
import 'react-notifications-component/dist/theme.css'
import MainRouter from './routes/Main'
import CookieConsent from './components/CookieConsent'
import { currenciesGet } from './actions/transaction'
import MaintenanceScreen from './components/MaintenanceScreen/MaintenanceScreen'

const AppNavbar = React.lazy(() => import('./components/layout/AppNavbar'))
const AppFooter = React.lazy(() => import('./components/layout/AppFooter'))

const App = () => {
  const dispatch = useDispatch()
  const maintenanceStatus = useSelector((state) => state.api.maintenance)

  useEffect(() => {
    const apiInterval = setInterval(() => {
      if (maintenanceStatus) {
        window.location.reload()
      } else {
        dispatch(currenciesGet('in'))
      }
    }, 10000)

    return () => {
      clearInterval(apiInterval)
    }
  }, [dispatch, maintenanceStatus])

  if (maintenanceStatus) {
    return (<MaintenanceScreen />)
  }
  return (
    <div>
      <ReactNotifications />
      <Suspense fallback={<Loader />}>
        <HelmetProvider>
          <BrowserRouter>
            <CookieConsent />
            <AppNavbar />
            <MainRouter />
            <AppFooter />
          </BrowserRouter>
        </HelmetProvider>
      </Suspense>
    </div>
  )
}

export default App
