/* eslint-disable */

import * as OneMoneyMailMobileApi from 'omm-rest-api-client'
import CryptoJS from 'crypto-js'
import axios from 'axios'
import { uuidv4 } from '../utils'
import store from '../store'


export const apiUrl = process.env.REACT_APP_DEBUG === 'true' && localStorage.getItem('currentHost')
  ? localStorage.getItem('currentHost')
  : process.env.REACT_APP_API_HOST;


class ApiConfig {
  // algorithm (optional) - for the HttpSignature
  constructor(algorithm) {
    // create axios instance with inclueded preRequest script to be passed to all api calls
    this.createAxiosInstance()

    if (process.env.REACT_APP_PUBLIC_KEY && process.env.REACT_APP_PRIVATE_KEY) {
      localStorage.setItem('publicKey', process.env.REACT_APP_PUBLIC_KEY)
      localStorage.setItem('privateKey', process.env.REACT_APP_PRIVATE_KEY)
    }

    // negotiate keys for communication
    this.setKeys()

    if (
      localStorage.getItem('publicKey') !== null ||
      localStorage.getItem('privateKey') !== null
    ) {
      // load saved keys from localStorage
      this.publicKey = localStorage.getItem('publicKey')
      this.privateKey = localStorage.getItem('privateKey')
    }
    // Set algorithm for decryption
    this.algorithm = algorithm || 'hmac-sha1'
  }

  createAxiosInstance() {
    this.axios_instance = axios.create({
      headers: {
        'Content-Type': 'application/vnd.api+json'
      }
    })
    this.axios_instance.interceptors.request.use(
      (config) => {
        config.headers = {
          ...config.headers,
          Accept: 'application/vnd.api+json',
          'Content-Type': 'application/vnd.api+json',
          'X-Date': new Date().toGMTString(),
          ...this.preRequestScript(config),
          Authorization:
            store.getState().login.accessToken !== ''
              ? store.getState().login.access_token
              : undefined
        }
        // Data need to be set for empty object because axios autmaticly remove Content-Type header from get request
        if (config.method === 'get') config.data = {}
        else if (config.method === 'delete') config.data = []
        return config
      },
      (error) =>
        // Do something with request error
        Promise.reject(error)
    )
  }

  async setKeys() {
    // if there is no keys get new keys from the api
    if (
      localStorage.getItem('publicKey') === null ||
      localStorage.getItem('privateKey') === null
    ) {
      const apiInstance = new OneMoneyMailMobileApi.ApiAccessApi(
        {
          apiKey: '',
          username: '',
          password: '',
          accessToken: '',
          basePath: apiUrl,
          baseOptions: {
            Accept: 'application/vnd.api+json'
          }
        },
        apiUrl,
        this.axios_instance
      )
      const inlineObject = {
        data: {
          type: 'devices',
          attributes: {
            deviceId: uuidv4()
          }
        }
      }
      await apiInstance
        .devicesAuthKeysPost('', inlineObject, 'pl_PL', {})
        .then((response) => {
          const { privateKey } = response.data.data.attributes
          const publicKey = response.data.data.id
          localStorage.setItem('publicKey', publicKey)
          localStorage.setItem('privateKey', privateKey)
          this.publicKey = publicKey
          this.privateKey = privateKey
        })
        .catch((err) => {
          console.error(err)
          alert(
            `There was an error while connecting to the server. Try to refresh the page ${apiUrl}`
          )
        })
    }
  }

  // code copied and modified from documentation of rest_api project
  // returns the authentication headers
  preRequestScript(request) {
    if (!this.publicKey || !this.privateKey) return {}
    const config = {
      algorithm: 'hmac-sha1',
      keyId: this.publicKey,
      secretkey: this.privateKey,
      headers: ['x-request', 'x-date'],
      projectPath: apiUrl
    }

    function computeHttpSignature(config, headerHash) {
      // compute sig here
      let signingBase = ''
      config.headers.forEach((h) => {
        if (signingBase !== '') {
          signingBase += '\n'
        }
        signingBase += `${h.toLowerCase()}: ${headerHash[h]}`
      })

      const hashf = (function () {
        switch (config.algorithm) {
          case 'hmac-sha1':
            return CryptoJS.HmacSHA1
          case 'hmac-sha256':
            return CryptoJS.HmacSHA256
          case 'hmac-sha512':
            return CryptoJS.HmacSHA512
          default:
            return null
        }
      })()

      const hash = hashf(signingBase, config.secretkey)
      const signatureOptions = {
        keyId: config.keyId,
        algorithm: config.algorithm,
        headers: config.headers,
        signature: CryptoJS.enc.Base64.stringify(hash)
      }

      const sig = `keyId="${signatureOptions.keyId}",algorithm="${signatureOptions.algorithm
        }",headers="${signatureOptions.headers.join(' ')}",signature="${signatureOptions.signature
        }"`

      return sig
    }

    const curDate = new Date().toGMTString()

    let targetUrl = request.url.trim() // there may be surrounding ws
    targetUrl = targetUrl.replace(new RegExp('^https?://[^/]+/'), '/') // strip hostname
    targetUrl = targetUrl.replace(config.projectPath, '')

    const index = targetUrl.indexOf('?')
    if (index !== -1) {
      targetUrl = targetUrl.substr(0, index)
    }
    const method = request.method.toLowerCase()

    let requestBody =
      request.data !== undefined && (method === 'post' || method === 'patch')
        ? request.data
        : ''
    if (method === 'delete') requestBody = '[]'
    const temp = `${method} ${targetUrl} ${requestBody}`
    const xRequest = CryptoJS.SHA1(temp)

    const headerHash = {
      'x-date': curDate,
      'x-request': xRequest
    }

    const sig = computeHttpSignature(config, headerHash)

    return {
      'X-Date': curDate,
      'X-Request': xRequest,
      Signature: sig,
      Authorization: `Signature ${sig}`
    }
  }
}

const api_config = new ApiConfig()

export default api_config.axios_instance
