// Notifications actions
export const NOTIFICATION = 'NOTIFICATION'

export const SET_LOADING = 'SET_LOADING'

export const SET_ELEMENT_VISIBILITY = 'SET_ELEMENT_VISIBILITY'
export const SET_MAINTENANCE_MODE = 'SET_MAINTENANCE_MODE'

// CMS Actions
export const CMS_GET_ABOUT_REPEAT_PAYMENT_PAGE_CONTENT = 'CMS_GET_ABOUT_REPEAT_PAYMENT_PAGE_CONTENT'
export const CMS_GET_ABOUT_US_PAGE_CONTENT = 'CMS_GET_ABOUTUS_PAGE_CONTENT'
export const CMS_GET_ADDRESS_PICKER_CONTENT = 'CMS_GET_ADDRESS_PICKER_CONTENT'
export const CMS_GET_BENEFICIARY_PAGE_CONTENT = 'CMS_GET_BENEFICIARY_PAGE_CONTENT'
export const CMS_GET_BRANCHES_CONTENT = 'CMS_GET_BRANCHES_CONTENT'
export const CMS_GET_CALCULATOR_CONTENT = 'CMS_GET_CALCULATOR_CONTENT'
export const CMS_GET_CARD_LIST_CONTENT = 'CMS_GET_CARD_LIST_CONTENT'
export const CMS_GET_CONTACT_US_PAGE_CONTENT = 'CMS_GET_CONTACT_US_PAGE_CONTENT'
export const CMS_GET_CUSTOMER_PAGE_CONTENT = 'CMS_GET_CUSTOMER_PAGE_CONTENT'
export const CMS_GET_EXCHANGE_ALERT_PAGE_CONTENT = 'CMS_GET_EXCHANGE_ALERT_PAGE_CONTENT'
export const CMS_GET_EXTRA_SERVICES_PAGE_CONTENT = 'CMS_GET_EXTRA_SERVICES_PAGE_CONTENT'
export const CMS_GET_FAQ_PAGE_CONTENT = 'CMS_GET_FAQ_PAGE_CONTENT'
export const CMS_GET_FEEDBACK_PAGE_CONTENT = 'CMS_GET_FEEDBACK_PAGE_CONTENT'
export const CMS_GET_FOOTER_CONTENT = 'CMS_GET_FOOTER_CONTENT'
export const CMS_GET_HISTORY_PAGE_CONTENT = 'CMS_GET_HISTORY_PAGE_CONTENT'
export const CMS_GET_LANDING_PAGE_CONTENT = 'CMS_GET_LANDING_PAGE_CONTENT'
export const CMS_GET_LOGIN_PAGE_CONTENT = 'CMS_GET_LOGIN_PAGE_CONTENT'
export const CMS_GET_LOGOUT_PAGE_CONTENT = 'CMS_GET_LOGOUT_PAGE_CONTENT'
export const CMS_GET_MOBILE_APPLICATION_PAGE_CONTENT = 'CMS_GET_MOBILE_APPLICATION_PAGE_CONTENT'
export const CMS_GET_NAVBAR_CONTENT = 'CMS_GET_NAVBAR_CONTENT'
export const CMS_GET_OPINIONS = 'CMS_GET_OPINIONS'
export const CMS_GET_PRODUCTS = 'CMS_GET_PRODUCTS'
export const CMS_GET_REGISTER_PAGE_CONTENT = 'CMS_GET_REGISTER_PAGE_CONTENT'
export const CMS_GET_REPEAT_PAYMENTS_LIST_PAGE_CONTENT = 'CMS_GET_REPEAT_PAYMENTS_LIST_PAGE_CONTENT'
export const CMS_GET_SAFE_TRANSFERS_CARD_CONTENT = 'CMS_GET_SAFE_TRANSFERS_CARD_CONTENT'
export const CMS_GET_SENDMONEY_CONTENT = 'CMS_GET_SENDMONEY_CONTENT'
export const CMS_GET_SET_PASSWORD_PAGE_CONTENT = 'CMS_GET_SET_PASSWORD_PAGE_CONTENT'
export const CMS_GET_TRACK_MODAL_CONTENT = 'CMS_GET_TRACK_MODAL_CONTENT'
export const CMS_GET_TRACKING_PAGE_CONTENT = 'CMS_GET_TRACKING_PAGE_CONTENT'
export const CMS_GET_TRANSFER_CURRENCY_PAGE_CONTENT = 'CMS_GET_TRANSFER_CURRENCY_PAGE_CONTENT'
export const CMS_GET_TRANSFER_EXPRESS_PAGE_CONTENT = 'CMS_GET_TRANSFER_EXPRESS_PAGE_CONTENT'
export const CMS_GET_TRANSFER_PAGE_CONTENT = 'CMS_GET_TRANSFER_PAGE_CONTENT'
export const CMS_GET_TRANSFER_STANDARD_PAGE_CONTENT = 'CMS_GET_TRANSFER_STANDARD_PAGE_CONTENT'
export const CMS_GET_TRANSFER_TEN_MIN_PAGE_CONTENT = 'CMS_GET_TRANSFER_TEN_MIN_PAGE_CONTENT'
export const CMS_GET_COMPLAINTS_PAGE_CONTENT = 'CMS_GET_COMPLAINTS_PAGE_CONTENT'
export const CMS_GET_TRANSFER_TYPE_INFO_CONTENT = 'CMS_GET_TRANSFER_TYPE_INFO_CONTENT'
export const CMS_GET_TRANSFER_UK_PAGE_CONTENT = 'CMS_GET_TRANSFER_UK_PAGE_CONTENT'
export const CMS_GET_UPLOAD_PAGE_CONTENT = 'CMS_GET_UPLOAD_PAGE_CONTENT'
export const CMS_GET_VIA_INTERNET_PAGE_CONTENT = 'CMS_GET_VIA_INTERNET_PAGE_CONTENT'
export const CMS_GET_VIA_PHONELINE_PAGE_CONTENT = 'CMS_GET_VIA_PHONELINE_PAGE_CONTENT'
export const CMS_GET_WIDGETS_CONTENT = 'CMS_GET_WIDGETS_CONTENT'
export const CMS_GET_ZLECENIA_STALE_PAGE_CONTENT = 'CMS_GET_ZLECENIA_STALE_PAGE_CONTENT'
export const CMS_GET_NOT_FOUND_PAGE_CONTENT = 'CMS_GET_NOT_FOUND_PAGE_CONTENT'
export const CMS_GET_SPLASH_SCREEN_CONTENT = 'CMS_GET_SPLASH_SCREEN_CONTENT'
export const CMS_GET_CONFIRM_ACCOUNT_PAGE_CONTENT = 'CMS_GET_CONFIRM_ACCOUNT_PAGE_CONTENT'
export const CMS_GET_OPINION_COMPONENT_CONTENT = 'CMS_GET_OPINION_COMPONENT_CONTENT'
export const CMS_GET_BENEFICIARY_LIST_CONTENT = 'CMS_GET_BENEFICIARY_LIST_CONTENT'
export const CMS_GET_PAYMENT_CARDS_LIST_CONTENT = 'CMS_GET_PAYMENT_CARDS_LIST_CONTENT'
export const CMS_GET_ACCOUNT_FUNCTIONALITY_CONTENT = 'CMS_GET_ACCOUNT_FUNCTIONALITY_CONTENT'
export const CMS_GET_TOUR_COMPONENT_CONTENT = 'CMS_GET_TOUR_COMPONENT_CONTENT'
export const CMS_GET_FRAUD_AWARENESS_CONTENT = 'CMS_GET_FRAUD_AWARENESS_CONTENT'
export const CMS_GET_FINANCIAL_MODAL_CONTENT = 'CMS_GET_FINANCIAL_MODAL_CONTENT'
export const CMS_GET_PRIVACY_PAGE_CONTENT = 'CMS_GET_PRIVACY_PAGE_CONTENT'
export const CMS_GET_DELETE_ACCOUNT_PAGE_CONTENT = 'CMS_GET_DELETE_ACCOUNT_PAGE_CONTENT'
export const CMS_INCREASE_ERROR_COUNTER = 'CMS_INCREASE_ERROR_COUNTER'
export const CMS_RESET_ERRORS_COUNTER = 'CMS_RESET_ERRORS_COUNTER'
export const CMS_GET_COOKIES_PAGE_CONTENT = 'CMS_GET_COOKIES_PAGE_CONTENT'
export const CMS_GET_COOKIES_CONSENT_POP_UPS = 'CMS_GET_COOKIES_CONSENT_POP_UPS'
export const CMS_GET_TYPES_OF_TRANSFER_PAGE_CONTENT = 'CMS_GET_TYPES_OF_TRANSFER_PAGE_CONTENT'
export const CMS_GET_FAQ_WIDGET_CONTENT = 'CMS_GET_FAQ_WIDGET_CONTENT'
export const CMS_GET_TERMS_PAGE_CONTENT = 'CMS_GET_TERMS_PAGE_CONTENT'
export const CMS_GET_B2B_PAGE_CONTENT = 'CMS_GET_B2B_PAGE_CONTENT'
export const CMS_GET_TRANSFER_ALERT_CONTENT = 'CMS_GET_TRANSFER_ALERT_CONTENT'
export const CMS_GET_LTF_CONTENT = 'CMS_GET_LTF_CONTENT'
export const CMS_GET_PAYMENT_METHODS_CONTENT = 'CMS_GET_PAYMENT_METHODS_CONTENT'

// Language Actions
export const SET_LANGUAGE = 'SET_LANGUAGE'

// Transfers Actions
export const GET_AVAILABE_TRANSFERS = 'GET_AVAILABE_TRANSFERS'

// Errors Actions
export const CLEAN_ERRORS = 'CLEAN_ERRORS'
export const GET_ERRORS = 'GET_ERRORS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'

// Customer login actions (customer login)
export const LOGIN_USER = 'LOGIN_USER'
export const REFRESH_TOKEN = 'REFRESH_TOKEN'
export const PASSWORD_RESET = 'PASSWORD_RESET'
export const LOGOUT = 'LOGOUT'
export const SHOW_COOKIE_CONSENT_POP_UP = 'SHOW_COOKIE_CONSENT_POP_UP'

// Registration Actions (registration)
export const SET_REGISTER_DATA = 'SET_REGISTER_DATA'
export const GET_COUNTRIES_REQUEST = 'GET_COUNTRIES_REQUEST'
export const GET_COUNTRIES = 'GET_COUNTRIES'
export const GET_ADDRESS = 'GET_ADDRESS'
export const GET_ADDRESS_REQUEST = 'GET_ADDRESS_REQUEST'

// Transaction Api
export const CURRENCIES_GET = 'CURRENCIES_GET'
export const CURRENCIES_IN_GET = 'CURRENCIES_IN_GET'
export const CURRENCIES_OUT_GET = 'CURRENCIES_OUT_GET'
export const BENEFICIARY_DELETE = 'BENEFICIARY_DELETE'
export const BENEFICIARY_PATCH = 'BENEFICIARY_PATCH'
export const BENEFICIARY_VERIFY_PATCH = 'BENEFICIARY_VERIFY_PATCH'
export const BENEFICIARY_VERIFY_POST = 'BENEFICIARY_VERIFY_POST'
export const BENEFICIARIES_POST_PATCH_REQUEST = 'BENEFICIARIES_POST_PATCH_REQUEST'
export const BENEFICIARIES_GET_REQUEST = 'BENEFICIARIES_GET_REQUEST'
export const BENEFICIARIES_GET = 'BENEFICIARIES_GET'
export const SET_SELECTED_BENEFICIARY = 'SET_SELECTED_BENEFICIARY'
export const PAYMENT_CARD_GET = 'PAYMENT_CARD_GET'
export const PROMOTION_CODES_GET_REQUEST = 'PROMOTION_CODES_GET_REQUEST'
export const PROMOTION_CODES_GET = 'PROMOTION_CODES_GET'
export const PROMOTION_CODE_SET = 'PROMOTION_CODE_SET'
export const TRANSACTION_GET = 'TRANSACTION_GET'
export const BENEFICIARY_VERIFICATION_CANCEL = 'BENEFICIARY_VERIFICATION_CANCEL'
export const TRANSACTION_POST_LTF_CONFIRMATION_CANCEL = 'TRANSACTION_POST_LTF_CONFIRMATION_CANCEL'
export const TRANSACTION_RECEIPT_GET = 'TRANSACTION_RECEIPT_GET'
export const PRODUCTS_GET = 'PRODUCTS_GET'
export const SURVEY_GET = 'SURVEY_GET'
export const SURVEY_CANCEL = 'SURVEY_CANCEL'

export const ANSWERS_POST = 'ANSWERS_POST'
export const CURRENT_PRODUCT_SET = 'CURRENT_PRODUCT_SET'
export const CLEAR_BENEFICIARIES = 'CLEAR_BENEFICIARIES'
export const ADD_BENEFICIARY = 'ADD_BENEFICIARY'
export const ADD_EW_BENEFICIARY = 'ADD_EW_BENEFICIARY'
export const VERIFIED_NEW_EW_BENEFICIARY = 'VERIFIED_NEW_EW_BENEFICIARY'
export const VERIFIED_NEW_BENEFICIARY = 'VERIFIED_NEW_BENEFICIARY'
export const CALCULATOR_DATA = 'CALCULATOR_DATA'
export const SET_TRANSACTION_STEP_ID = 'SET_TRANSACTION_STEP_ID'
export const GET_TRANSACTION_SOURCE_OF_FUNDS = 'GET_TRANSACTION_SOURCE_OF_FUNDS'
export const GET_TRANSACTION_SOURCE_OF_FUNDS_CATEGORY = 'GET_TRANSACTION_SOURCE_OF_FUNDS_CATEGORY'
export const GET_TRANSACTION_PURPOSE_DICTIONARY = 'GET_TRANSACTION_PURPOSE_DICTIONARY'

// Transaction History Api
export const GET_TRANSACTION_HISTORY_RECEIPT = 'GET_TRANSACTION_HISTORY_RECEIPT'
export const GET_TRANSACTION_HISTORY = 'GET_TRANSACTION_HISTORY'
export const SEND_CONFIRMATION_EMAIL = 'SEND_CONFIRMATION_EMAIL'

// Customer Account Api
export const GET_REFFERAL_LINK = 'GET_REFFERAL_LINK'
export const GET_CUSTOMER_DATA = 'GET_CUSTOMER_DATA'
export const PATCH_CUSTOMER_ACCEPTATIONS = 'PATCH_CUSTOMER_ACCEPTATIONS'
export const PATCH_CUSTOMER_PASSWORD = 'PATCH_CUSTOMER_PASSWORD'
export const PATCH_CUSTOMER_ADDRESS = 'PATCH_CUSTOMER_ADDRESS'
export const POST_CUSTOMER_PHONE = 'POST_CUSTOMER_PHONE'
export const GET_CUSTOMER_COUNTRIES = 'GET_CUSTOMER_COUNTRIES'
export const GET_CUSTOMER_CARDS = 'GET_CUSTOMER_CARDS'
export const ACCOUNT_ACTIVATE = 'ACCOUNT_ACTIVATE'
export const LIMIT_GET = 'LIMIT_GET'
export const ID_SCAN_URL_GET = 'ID_SCAN_URL_GET'
export const SET_ID_SCAN_COMPLETE_STATUS = 'SET_ID_SCAN_COMPLETE_STATUS'

// Repeat Payment Api
export const REPEAT_PAYMENTS_STEP1 = 'REPEAT_PAYMENTS_STEP1'
export const REPEAT_PAYMENTS_STEP2 = 'REPEAT_PAYMENTS_STEP2'
export const REPEAT_PAYMENTS_STEP3 = 'REPEAT_PAYMENTS_STEP3'
export const REPEAT_PAYMENTS_STEP4 = 'REPEAT_PAYMENTS_STEP4'
export const REPEAT_PAYMENTS_STEP1_PATCH = 'REPEAT_PAYMENTS_STEP1_PATCH'
export const REPEAT_PAYMENTS_STEP2_PATCH = 'REPEAT_PAYMENTS_STEP2_PATCH'
export const REPEAT_PAYMENTS_STEP3_PATCH = 'REPEAT_PAYMENTS_STEP3_PATCH'
export const REPEAT_PAYMENTS_STEP4_PATCH = 'REPEAT_PAYMENTS_STEP4_PATCH'
export const ENTER_RPI = 'ENTER_RPI'
export const GET_PERIODS = 'GET_PERIODS'
export const GET_LIST_OF_REPEAT_PAYMENTS = 'GET_LIST_OF_REPEAT_PAYMENTS'
export const DELETE_REPEAT_PAYMENT = 'DELETE_REPEAT_PAYMENT'
export const GET_RP_SURVEY = 'GET_RP_SURVEY'
export const POST_RP_SURVEY = 'POST_RP_SURVEY'
export const GET_REPEAT_PAYMENT = 'RGET_REPEAT_PAYMENT'
export const GET_RPI_SCHEDULE_REQUEST = 'GET_RPI_SCHEDULE_REQUEST'
export const GET_RPI_SCHEDULE_FAILURE = 'GET_RPI_SCHEDULE_FAILURE'
export const GET_RPI_SCHEDULE = 'GET_RPI_SCHEDULE'
export const DELETE_SINGLE_SCHEDULE_REQUEST = 'DELETE_SINGLE_SCHEDULE_REQUEST'
export const DELETE_SINGLE_SCHEDULE_FAILURE = 'DELETE_SINGLE_SCHEDULE_FAILURE'
export const DELETE_SINGLE_SCHEDULE = 'DELETE_SINGLE_SCHEDULE'
export const REQUEST_NEW_CARD = 'REQUEST_NEW_CARD'
export const SET_RPI_CALCULATOR_DATA = 'SET_RPI_CALCULATOR_DATA'

// Surveys Api
export const GET_SURVEY_QUESTION = 'GET_SURVEY_QUESTION'

// App data reset action
export const RESET_APP_DATA = 'RESET_APP_DATA'

// Custom RPI action
export const GO_TO_EDIT_RPI = 'GO_TO_EDIT_RPI'

// Agents Api
export const GET_AGENTS = 'GET_AGENTS'

// Contact Api
export const POST_CONTACT = 'POST_CONTACT'

// Tracking Api
export const GET_TRACKING = 'GET_TRACKING'

// Calendar Api
export const GET_CALENDAR = 'GET_CALENDAR'

// Customer Sources Api
export const GET_CUSTOMER_SOURCES = 'GET_CUSTOMER_SOURCES'
export const GET_CUSTOMER_COMPANY_INFO_SOURCES = 'GET_CUSTOMER_COMPANY_INFO_SOURCES'

// Customer Source Api
export const PATCH_CUSTOMER_SOURCE = 'PATCH_CUSTOMER_SOURCE'

// Card Verification Api
export const GET_ACTIVE_VERIFICATION = 'GET_ACTIVE_VERIFICATION'
export const POST_CARD_VERIFICATION = 'POST_CARD_VERIFICATION'

// Feature Api TODO: finish Feature Api when feature togle will be available
// export const GET_FEATURE_STATUS = 'GET_FEATURE_STATUS';

// Card Api
export const CARD_POST = 'CARD_POST'
export const CARD_PATCH = 'CARD_PATCH'
export const CARD_GET = 'CARD_GET'

// Payment Card Api
export const DELETE_CARD = 'DELETE_CARD'
export const RESET_DELETED = 'RESET_DELETED' // to reset success variable

// PPI Blog Api
export const GET_BLOG_POSTS_REQUEST = 'GET_BLOG_POSTS_REQUEST'
export const GET_BLOG_POSTS = 'GET_BLOG_POSTS'
export const GET_BLOG_POSTS_FAILURE = 'GET_BLOG_POSTS_FAILURE'
export const PETCH_BLOG_POST = 'PETCH_BLOG_POST'
export const GET_B2B_BLOG_POSTS_REQUEST = 'GET_B2B_BLOG_POSTS_REQUEST'
export const GET_B2B_BLOG_POST = 'GET_B2B_BLOG_POST'

// Charity
export const SET_CHARITY = 'SET_CHARITY'

export const SET_LOCATION = 'SET_LOCATION'

// AML
export const GET_FIELD_VALUES = 'GET_FIELD_VALUES'

// Beneficiary
export const GET_BENEFICIARIES_RELATIONSHIP_DICTIONARY = 'GET_BENEFICIARIES_RELATIONSHIP_DICTIONARY'

// Financial
export const GET_FINANCIAL_DATA = 'GET_FINANCIAL_DATA'
export const PATCH_FINANCIAL_DATA = 'PATCH_FINANCIAL_DATA'

// On Screen Guidance
export const SET_ON_SCREEN_GUIDANCE_VISIBILITY = 'SET_ON_SCREEN_GUIDANCE_VISIBILITY'
