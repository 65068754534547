import { SET_ELEMENT_VISIBILITY } from '../actions/types'
import hidableElements from '../config/hidableElements'

const initialState = {
  elements: Object.keys(hidableElements)?.map((element) => ({
    name: hidableElements[element],
    visible: false
  }))
}

export default (state = initialState, action = { type: undefined, payload: undefined }) => {
  switch (action.type) {
    case SET_ELEMENT_VISIBILITY:
      return {
        ...state,
        elements: state.elements?.map((element) => {
          if (element.name === action.payload.name) return action.payload
          return element
        })
      }

    default:
      return state
  }
}
