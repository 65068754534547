import { SET_CHARITY } from '../actions/types'

const initialState = {}

export default (state = initialState, action = { type: undefined, payload: undefined }) => {
  switch (action.type) {
    case SET_CHARITY:
      return {
        ...state,
        charity: action.payload.charity
      }

    default:
      return state
  }
}
