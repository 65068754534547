import { LOGIN_USER, PASSWORD_RESET, REFRESH_TOKEN, RESET_APP_DATA, SHOW_COOKIE_CONSENT_POP_UP } from '../actions/types'

async function getUserData() {
  const userData = localStorage.getItem('loggedUser')
  return userData || {}
}

const initialState = {
  authVerified: false,
  ...getUserData()
}

export default (state = initialState, action = { type: undefined, payload: undefined }) => {
  const expiresIn = new Date(new Date().getTime() + (action?.payload?.expires_in || 0) * 1000)
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        ...action?.payload,
        timeLeft: expiresIn
      }

    // TODO: make a bussiness decision what to do in case of reset
    case PASSWORD_RESET:
      return {
        ...state
      }

    case SHOW_COOKIE_CONSENT_POP_UP:
      return {
        ...state,
        ...action?.payload
      }

    case REFRESH_TOKEN:
      return {
        ...state,
        ...action?.payload,
        timeLeft: expiresIn
      }

    case RESET_APP_DATA:
      return {}

    default:
      return state
  }
}
