import * as OneMoneyMailMobileApi from 'omm-rest-api-client'
import axiosInstance, { apiUrl } from '../config/api'
import { GET_ERRORS, LOGIN_USER, PASSWORD_RESET, REFRESH_TOKEN, RESET_APP_DATA } from './types'
import Pages from '../Enum/Pages'
import { getPath } from '../MultilingualRouter/MultilingualRouter'

const apiInstance = new OneMoneyMailMobileApi.CustomerLoginApi({}, apiUrl, axiosInstance)
export const setUser = (userData) => (dispatch) => {
  sessionStorage.setItem('userData', JSON.stringify(userData))
  dispatch({ type: LOGIN_USER, payload: userData })
}

export const setRefreshToken = (tokenData) => (dispatch) => {
  sessionStorage.setItem('refresh_token', tokenData.refresh_token)
  dispatch({ type: REFRESH_TOKEN, payload: tokenData })
}

export const resetPassword
  = ({ dateOfBirth, email }) =>
    (dispatch) => {
      const data = {
        data: {
          type: 'customers',
          attributes: {
            dateOfBirth,
            email
          }
        }
      }

      apiInstance
        .customersPasswordPost(data, 'pl_PL')
        .then(() => {
        // in case of success response is empty
          dispatch({ type: PASSWORD_RESET })
        })
        .catch((err) => {
          dispatch({
            type: GET_ERRORS,
            payload: { login: err.response?.data }
          })
        })
    }
export const logout = (lang, navigate) => (dispatch) => {
  localStorage.setItem('lang', lang || 'pl_PL')
  sessionStorage.removeItem('userData')
  sessionStorage.removeItem('refresh_token')
  sessionStorage.removeItem('authVerified')
  localStorage.removeItem('publicKey')
  localStorage.removeItem('privateKey')
  sessionStorage.removeItem('rpiEdit')
  sessionStorage.removeItem('calculatorData')

  dispatch({ type: RESET_APP_DATA })

  navigate(getPath(Pages.logoutPage, lang))
}
export const setAuthCodeVerified = (value) => () => {
  sessionStorage.setItem('authVerified', value)
}
