import { SET_LANGUAGE } from '../actions/types'

const initialState = {
  lang: localStorage.getItem('lang') || 'pl_PL' // default language of application (pl_PL or en_US)
}

export default (state = initialState, action = { type: undefined, payload: undefined }) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        lang: action.payload.lang
      }

    default:
      return state
  }
}
