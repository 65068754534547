import { useSelector } from 'react-redux'
import usePrevious from './usePrevious'

const useLang = () => {
  const lang = useSelector((state) => state.lang.lang)
  const prevLang = usePrevious(lang)

  return { lang, prevLang }
}

export default useLang
