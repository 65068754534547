import { GET_ERRORS, CLEAR_ERRORS } from '../actions/types'

const initialState = {}

export default (state = initialState, action = { type: undefined, payload: undefined }) => {
  switch (action.type) {
    case GET_ERRORS:
      return { ...state, ...action.payload }

    case CLEAR_ERRORS: {
      return {}
    }

    default:
      return state
  }
}
