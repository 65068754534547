import { SET_ON_SCREEN_GUIDANCE_VISIBILITY } from '../actions/types'
import tourElements from '../config/tour'

const initialState = {
  tours: Object.keys(tourElements)?.map((element) => ({
    name: tourElements[element],
    completed: false
  }))
}

export default (state = initialState, action = { type: undefined, payload: undefined }) => {
  switch (action.type) {
    case SET_ON_SCREEN_GUIDANCE_VISIBILITY:
      return {
        ...state,
        tours: state.tours?.map((element) => {
          if (element.name === action.payload.name) return action.payload
          return element
        })
      }

    default:
      return state
  }
}
