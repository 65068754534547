import { useEffect, useRef } from 'react'
import { get } from 'react-hook-form'
import store from '../store'

// returns UUID v4 https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
export const uuidv4 = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line
    const r = (Math.random() * 16) | 0
    // eslint-disable-next-line
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })

// hook used to return the previous value of passed parameter
export const usePrevious = (value) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

// Format phone number
export const formatPhoneNumber = (phoneNumber) => {
  if (phoneNumber?.length > 0) {
    return `+${phoneNumber.slice(0, 2)} ${phoneNumber.slice(2)}`
  }
  return '---'
}

// Format exchangeRate
export const formatExchangeRate = (exchangeRate) => parseFloat(exchangeRate).toFixed(4)

// Get input name and cms path, then return text from state cms
export const getInputLabel = (inputName, inputCmsPath) => {
  if (!inputName || !inputCmsPath) {
    return ''
  }
  const cms = store.getState()?.cms
  const inputCms = get(cms, inputCmsPath)
  return inputCms?.find((obj) => obj.keyName === inputName)?.label || ''
}

// Get cms path, then return text from state cms
export const getTextFromCmsPath = (cmsPath) => {
  if (!cmsPath) {
    return ''
  }
  const cms = store.getState()?.cms
  const text = get(cms, cmsPath)
  return typeof text === 'string' ? text : ''
}

// Get inputs schema
export const getInputsSchema = (formSchema) =>
  formSchema?.reduce(
    (obj, item) =>
      Object.assign(obj, {
        [item.keyName]: item.errors?.reduce(
          (errors, error) =>
            Object.assign(errors, {
              [error.type]: error.message
            }),
          {}
        )
      }),
    {}
  )
