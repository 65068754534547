import { CARD_POST, CARD_PATCH, CARD_GET } from '../actions/types'

const initialState = {}

export default (state = initialState, action = { type: undefined, payload: undefined }) => {
  switch (action.type) {
    case CARD_POST:
      return {
        ...state,
        ...action.payload.data
      }

    case CARD_PATCH:
      return {
        ...state,
        patch: true
      }

    case CARD_GET:
      return {
        ...state,
        ...action.payload
      }

    default:
      return state
  }
}
