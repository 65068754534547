import { SET_LOADING } from '../actions/types'

const initialState = {
  loading: false
}

export default (state = initialState, action = { type: undefined, payload: undefined }) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload.loading
      }

    default:
      return state
  }
}
