import { GET_BENEFICIARIES_RELATIONSHIP_DICTIONARY, RESET_APP_DATA } from '../actions/types'

const initialState = {
    beneficiaryValues: null,
    beneficiaryValuesLoading: true
};

export default (state = initialState, action = { type: undefined, payload: undefined }) => {
    switch (action.type) {
        case GET_BENEFICIARIES_RELATIONSHIP_DICTIONARY:
            return {
                ...state,
                beneficiaryValues: action.payload,
                beneficiaryValuesLoading: false
            }

        case RESET_APP_DATA:
            return {}

        default:
            return state
    }
}
