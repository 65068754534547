import { GET_TRANSACTION_HISTORY, GET_TRANSACTION_HISTORY_RECEIPT, RESET_APP_DATA } from '../actions/types'

const initialState = {
  last: '0',
  historyLoaded: false
}

export default (state = initialState, action = { type: undefined, payload: undefined }) => {
  switch (action.type) {
    case GET_TRANSACTION_HISTORY:
      return {
        ...state,
        history: action?.payload?.data?.attributes || [],
        historyLoaded: true,
        last: action.payload?.links?.last?.match(/\d+/)[0],
        total: action.payload?.links?.self?.meta?.count
      }

    case GET_TRANSACTION_HISTORY_RECEIPT:
      return {
        ...state,
        receipt: action.payload.data
      }

    case RESET_APP_DATA:
      return null

    default:
      return {
        ...state
      }
  }
}
