/* eslint-disable max-len */

import {
  CMS_GET_ABOUT_REPEAT_PAYMENT_PAGE_CONTENT,
  CMS_GET_ABOUT_US_PAGE_CONTENT,
  CMS_GET_ADDRESS_PICKER_CONTENT,
  CMS_GET_BENEFICIARY_PAGE_CONTENT,
  CMS_GET_BRANCHES_CONTENT,
  CMS_GET_CALCULATOR_CONTENT,
  CMS_GET_CARD_LIST_CONTENT,
  CMS_GET_CONTACT_US_PAGE_CONTENT,
  CMS_GET_CUSTOMER_PAGE_CONTENT,
  CMS_GET_EXCHANGE_ALERT_PAGE_CONTENT,
  CMS_GET_EXTRA_SERVICES_PAGE_CONTENT,
  CMS_GET_FAQ_PAGE_CONTENT,
  CMS_GET_FEEDBACK_PAGE_CONTENT,
  CMS_GET_FOOTER_CONTENT,
  CMS_GET_HISTORY_PAGE_CONTENT,
  CMS_GET_LANDING_PAGE_CONTENT,
  CMS_GET_LOGIN_PAGE_CONTENT,
  CMS_GET_LOGOUT_PAGE_CONTENT,
  CMS_GET_MOBILE_APPLICATION_PAGE_CONTENT,
  CMS_GET_NAVBAR_CONTENT,
  CMS_GET_OPINIONS,
  CMS_GET_PRODUCTS,
  CMS_GET_REGISTER_PAGE_CONTENT,
  CMS_GET_REPEAT_PAYMENTS_LIST_PAGE_CONTENT,
  CMS_GET_SAFE_TRANSFERS_CARD_CONTENT,
  CMS_GET_SENDMONEY_CONTENT,
  CMS_GET_SET_PASSWORD_PAGE_CONTENT,
  CMS_GET_TRACK_MODAL_CONTENT,
  CMS_GET_TRACKING_PAGE_CONTENT,
  CMS_GET_TRANSFER_CURRENCY_PAGE_CONTENT,
  CMS_GET_TRANSFER_EXPRESS_PAGE_CONTENT,
  CMS_GET_TRANSFER_PAGE_CONTENT,
  CMS_GET_TRANSFER_STANDARD_PAGE_CONTENT,
  CMS_GET_TRANSFER_TEN_MIN_PAGE_CONTENT,
  CMS_GET_TRANSFER_UK_PAGE_CONTENT,
  CMS_GET_UPLOAD_PAGE_CONTENT,
  CMS_GET_VIA_INTERNET_PAGE_CONTENT,
  CMS_GET_VIA_PHONELINE_PAGE_CONTENT,
  CMS_GET_WIDGETS_CONTENT,
  CMS_GET_ZLECENIA_STALE_PAGE_CONTENT,
  CMS_GET_NOT_FOUND_PAGE_CONTENT,
  CMS_GET_SPLASH_SCREEN_CONTENT,
  CMS_GET_CONFIRM_ACCOUNT_PAGE_CONTENT,
  CMS_GET_OPINION_COMPONENT_CONTENT,
  CMS_GET_BENEFICIARY_LIST_CONTENT,
  CMS_GET_PAYMENT_CARDS_LIST_CONTENT,
  CMS_GET_ACCOUNT_FUNCTIONALITY_CONTENT,
  CMS_GET_TOUR_COMPONENT_CONTENT,
  CMS_GET_FRAUD_AWARENESS_CONTENT,
  CMS_GET_FINANCIAL_MODAL_CONTENT,
  CMS_GET_PRIVACY_PAGE_CONTENT,
  CMS_GET_DELETE_ACCOUNT_PAGE_CONTENT,
  CMS_INCREASE_ERROR_COUNTER,
  CMS_RESET_ERRORS_COUNTER,
  CMS_GET_COOKIES_PAGE_CONTENT,
  CMS_GET_COOKIES_CONSENT_POP_UPS,
  CMS_GET_COMPLAINTS_PAGE_CONTENT,
  CMS_GET_TYPES_OF_TRANSFER_PAGE_CONTENT,
  CMS_GET_FAQ_WIDGET_CONTENT,
  CMS_GET_TERMS_PAGE_CONTENT,
  CMS_GET_B2B_PAGE_CONTENT,
  CMS_GET_TRANSFER_ALERT_CONTENT,
  CMS_GET_LTF_CONTENT,
  CMS_GET_PAYMENT_METHODS_CONTENT
} from '../actions/types'

const initialState = {
  landingPage: undefined,
  loginPage: {},
  navbar: {},
  footer: undefined,
  calculator: {
    types: [
      {
        country: '',
        currency: '',
        types: [
          {
            name: '',
            price: ''
          }
        ]
      }
    ],
    title: '',
    input_sending_placeholder: '',
    input_receiving_placeholder: '',
    exchange_rate: '',
    fee: '',
    sum: '',
    submit_btn: ''
  },
  sendMoney: {},
  safeTransfersCard: {
    title: 'Safe transfers',
    description:
      'We care about the safety of our customers, which is why we use the best systems that ensure the safety of performed transactions.',
    cards: []
  },
  registerPage: {},
  aboutusPage: {},
  branchesPage: {},
  extra_services_page: {},
  transferStandardPage: {},
  transferExpressPage: {},
  transferTenMinPage: {},
  history_page: {},
  customerPage: {},
  exchange_alert_page: {
    title: '',
    description: ''
  },
  calendar_page: {
    title: 'Calendar',
    events: [
      {
        title: 'Test-1',
        start: new Date(2021, 0, 1),
        end: new Date(2021, 0, 1),
        type: 'payment',
        status: 'Next planned transfer will be made soon. Check your bank account balance.',
        beneficiary: 'Tetowy Jan',
        accountNumber: '77249000059245183414482526',
        amount: '21.00 PLN'
      },
      {
        title: 'Test-2',
        start: new Date(2021, 0, 6),
        end: new Date(2021, 0, 6),
        type: 'event',
        status: 'Next planned event will be made soon.',
        beneficiary: 'Rafal',
        accountNumber: '88249000059245183414482529',
        amount: '37.00 PLN'
      }
    ]
  },
  mobileApplicationPage: {},
  contactUsPage: {},
  zleceniaStalePage: {},
  repeat_payments_list_page: {},
  trackingPage: undefined,
  uploadPage: undefined,
  beneficiaryPage: undefined,
  cmsErrorsCounter: 0,
  cookiesConsentPopUps: {}
}

export const cmsReducer = (state = initialState, action = { type: undefined, payload: undefined }) => {
  switch (action.type) {
    case CMS_GET_LANDING_PAGE_CONTENT:
      return { ...state, landingPage: action.payload }

    case CMS_GET_LOGIN_PAGE_CONTENT:
      return { ...state, loginPage: action.payload }

    case CMS_GET_NAVBAR_CONTENT:
      return { ...state, navbar: action.payload }

    case CMS_GET_FOOTER_CONTENT:
      return { ...state, footer: action.payload }

    case CMS_GET_CALCULATOR_CONTENT:
      return { ...state, calculator: action.payload }

    case CMS_GET_SENDMONEY_CONTENT:
      return { ...state, sendMoney: action.payload }

    case CMS_GET_SAFE_TRANSFERS_CARD_CONTENT:
      return { ...state, safeTransfersCard: action.payload }

    case CMS_GET_REGISTER_PAGE_CONTENT:
      return { ...state, registerPage: action.payload }

    case CMS_GET_TRANSFER_PAGE_CONTENT:
      return {
        ...state,
        transferPage: {
          ...action.payload.data,
          lang: action.payload.lang
        }
      }

    case CMS_GET_NOT_FOUND_PAGE_CONTENT:
      return {
        ...state,
        notFoundPage: action.payload
      }

    case CMS_GET_SPLASH_SCREEN_CONTENT:
      return {
        ...state,
        splashScreen: action.payload
      }

    case CMS_GET_FEEDBACK_PAGE_CONTENT:
      return { ...state, feedbackPage: action.payload }

    case CMS_GET_OPINIONS:
      return { ...state, opinions: action.payload }

    case CMS_GET_ABOUT_US_PAGE_CONTENT:
      return { ...state, aboutusPage: action.payload }

    case CMS_GET_TRANSFER_STANDARD_PAGE_CONTENT:
      return { ...state, transferStandardPage: action.payload }

    case CMS_GET_TRANSFER_EXPRESS_PAGE_CONTENT:
      return { ...state, transferExpressPage: action.payload }

    case CMS_GET_TRANSFER_TEN_MIN_PAGE_CONTENT:
      return { ...state, transferTenMinPage: action.payload }

    case CMS_GET_MOBILE_APPLICATION_PAGE_CONTENT:
      return { ...state, mobileApplicationPage: action.payload }

    case CMS_GET_TRANSFER_CURRENCY_PAGE_CONTENT:
      return { ...state, transferCurrencyPage: action.payload }

    case CMS_GET_VIA_INTERNET_PAGE_CONTENT:
      return { ...state, viaInternetPage: action.payload }

    case CMS_GET_TRACKING_PAGE_CONTENT:
      return { ...state, trackingPage: action.payload }

    case CMS_GET_TRACK_MODAL_CONTENT:
      return { ...state, trackingModal: action.payload }

    case CMS_GET_CUSTOMER_PAGE_CONTENT:
      return { ...state, customerPage: action.payload }

    case CMS_GET_CONTACT_US_PAGE_CONTENT:
      return { ...state, contactUsPage: action.payload }

    case CMS_GET_ZLECENIA_STALE_PAGE_CONTENT:
      return { ...state, zleceniaStalePage: action.payload }

    case CMS_GET_REPEAT_PAYMENTS_LIST_PAGE_CONTENT:
      return { ...state, repeat_payments_list_page: action.payload }

    case CMS_GET_HISTORY_PAGE_CONTENT:
      return { ...state, history_page: action.payload }

    case CMS_GET_FAQ_PAGE_CONTENT:
      return { ...state, faq: action.payload }

    case CMS_GET_EXCHANGE_ALERT_PAGE_CONTENT:
      return { ...state, exchange_alert_page: action.payload }

    case CMS_GET_ADDRESS_PICKER_CONTENT:
      return { ...state, addressPicker: action.payload }

    case CMS_GET_UPLOAD_PAGE_CONTENT:
      return { ...state, uploadPage: action.payload }

    case CMS_GET_CARD_LIST_CONTENT:
      return { ...state, cardList: action.payload }

    case CMS_GET_BENEFICIARY_PAGE_CONTENT:
      return { ...state, beneficiaryPage: action.payload }

    case CMS_GET_WIDGETS_CONTENT:
      return { ...state, widgets: action.payload }

    case CMS_GET_VIA_PHONELINE_PAGE_CONTENT:
      return { ...state, viaPhoneline: action.payload }

    case CMS_GET_BRANCHES_CONTENT:
      return { ...state, branchesPage: action.payload }

    case CMS_GET_ABOUT_REPEAT_PAYMENT_PAGE_CONTENT:
      return { ...state, aboutRepeatPayment: action.payload }

    case CMS_GET_EXTRA_SERVICES_PAGE_CONTENT:
      return { ...state, extraServices: action.payload }

    case CMS_GET_TRANSFER_UK_PAGE_CONTENT:
      return { ...state, transferUk: action.payload }

    case CMS_GET_SET_PASSWORD_PAGE_CONTENT:
      return { ...state, setPasswordPage: action.payload }

    case CMS_GET_PRODUCTS:
      return { ...state, transfers: action.payload }

    case CMS_GET_CONFIRM_ACCOUNT_PAGE_CONTENT:
      return { ...state, confirmAccountPage: action.payload }

    case CMS_GET_OPINION_COMPONENT_CONTENT:
      return { ...state, opinionComponent: action.payload }

    case CMS_GET_BENEFICIARY_LIST_CONTENT:
      return { ...state, beneficiaryList: action.payload }

    case CMS_GET_PAYMENT_CARDS_LIST_CONTENT:
      return { ...state, paymentCardsList: action.payload }

    case CMS_GET_ACCOUNT_FUNCTIONALITY_CONTENT:
      return { ...state, accountFunctionality: action.payload }

    case CMS_GET_TOUR_COMPONENT_CONTENT:
      return { ...state, tourComponent: action.payload }

    case CMS_GET_FRAUD_AWARENESS_CONTENT:
      return { ...state, fraudAwareness: action.payload }

    case CMS_GET_FINANCIAL_MODAL_CONTENT:
      return { ...state, financialModal: action.payload }

    case CMS_GET_PRIVACY_PAGE_CONTENT:
      return { ...state, privacyPage: action.payload }

    case CMS_GET_DELETE_ACCOUNT_PAGE_CONTENT:
      return { ...state, deleteAccountPage: action.payload }

    case CMS_INCREASE_ERROR_COUNTER:
      return { ...state, cmsErrorsCounter: state.cmsErrorsCounter + 1 }

    case CMS_RESET_ERRORS_COUNTER:
      return { ...state, cmsErrorsCounter: 0 }

    case CMS_GET_COOKIES_PAGE_CONTENT:
      return { ...state, cookiesPage: action.payload }

    case CMS_GET_COOKIES_CONSENT_POP_UPS:
      return { ...state, cookiesConsentPopUps: action.payload }

    case CMS_GET_COMPLAINTS_PAGE_CONTENT:
      return { ...state, complaintsPage: action.payload }

    case CMS_GET_TYPES_OF_TRANSFER_PAGE_CONTENT:
      return { ...state, typesOfTransferPage: action.payload }

    case CMS_GET_FAQ_WIDGET_CONTENT:
      return { ...state, faqWidget: action.payload }

    case CMS_GET_LOGOUT_PAGE_CONTENT:
      return { ...state, logoutPage: action.payload }

    case CMS_GET_TERMS_PAGE_CONTENT:
      return { ...state, termsPage: action.payload }

    case CMS_GET_B2B_PAGE_CONTENT:
      return { ...state, b2bPage: action.payload }

    case CMS_GET_TRANSFER_ALERT_CONTENT:
      return { ...state, transferAlert: action.payload }

    case CMS_GET_LTF_CONTENT:
      return { ...state, ltf: action.payload }

    case CMS_GET_PAYMENT_METHODS_CONTENT:
      return { ...state, paymentMethods: action.payload }

    default:
      return state
  }
}

export default cmsReducer
