import * as OneMoneyMailMobileApi from 'omm-rest-api-client'
import axiosInstance, { apiUrl } from '../config/api'

import {
  ADD_BENEFICIARY,
  ADD_EW_BENEFICIARY,
  ANSWERS_POST,
  BENEFICIARIES_GET,
  BENEFICIARIES_GET_REQUEST,
  BENEFICIARY_DELETE,
  BENEFICIARY_PATCH,
  BENEFICIARY_VERIFY_PATCH,
  BENEFICIARY_VERIFY_POST,
  CALCULATOR_DATA,
  CLEAR_BENEFICIARIES,
  CURRENCIES_GET,
  CURRENCIES_IN_GET,
  CURRENCIES_OUT_GET,
  CURRENT_PRODUCT_SET,
  GET_ERRORS,
  PRODUCTS_GET,
  PROMOTION_CODE_SET,
  PROMOTION_CODES_GET,
  PROMOTION_CODES_GET_REQUEST,
  SEND_CONFIRMATION_EMAIL,
  SET_SELECTED_BENEFICIARY,
  SURVEY_GET,
  VERIFIED_NEW_BENEFICIARY,
  VERIFIED_NEW_EW_BENEFICIARY,
  SET_TRANSACTION_STEP_ID,
  GET_TRANSACTION_SOURCE_OF_FUNDS,
  GET_TRANSACTION_SOURCE_OF_FUNDS_CATEGORY,
  GET_TRANSACTION_PURPOSE_DICTIONARY,
  SET_MAINTENANCE_MODE
} from './types'

const apiInstance = new OneMoneyMailMobileApi.TransactionsApi({}, apiUrl, axiosInstance)
const currenciesApiInstance = new OneMoneyMailMobileApi.CurrenciesApi({}, apiUrl, axiosInstance)
const beneficiariesApiInstance = new OneMoneyMailMobileApi.BeneficiariesApi({}, apiUrl, axiosInstance)
const surveysApiInstance = new OneMoneyMailMobileApi.SurveysApi({}, apiUrl, axiosInstance)
const amlApiInstance = new OneMoneyMailMobileApi.AmlApi({}, apiUrl, axiosInstance)

export const setCurrentProduct = (productId) => (dispatch) =>
  dispatch({ type: CURRENT_PRODUCT_SET, payload: productId })

export const setTrxCalculatorData = (calculatorData) => (dispatch) =>
  dispatch({ type: CALCULATOR_DATA, payload: calculatorData })

export const clearBeneficiaries = () => (dispatch) => dispatch({ type: CLEAR_BENEFICIARIES })

export const addBeneficiary = (newBeneficiary) => (dispatch) =>
  dispatch({ type: ADD_BENEFICIARY, payload: newBeneficiary })

export const addEwBeneficiary = (newBeneficiary) => (dispatch) =>
  dispatch({ type: ADD_EW_BENEFICIARY, payload: newBeneficiary })

export const setVerifiedNewBeneficiary = (verifiedBeneficiary) => (dispatch) =>
  dispatch({ type: VERIFIED_NEW_BENEFICIARY, payload: verifiedBeneficiary })

export const verifiedNewEwBeneficiary = (verifiedBeneficiary) => (dispatch) =>
  dispatch({ type: VERIFIED_NEW_EW_BENEFICIARY, payload: verifiedBeneficiary })

export const editBeneficiary = (beneficiary) => (dispatch) => {
  dispatch({ type: BENEFICIARY_PATCH, payload: beneficiary })
}

// to be called with currencyIn as an empty string for some clever reasons
export const currenciesGet = (currencyIn) => (dispatch, getState) => {
  currenciesApiInstance
    .currenciesInGet(currencyIn, getState().lang.lang || 'en_US')
    .then((response) => {
      dispatch({
        type: SET_MAINTENANCE_MODE,
        payload: false
      })
      if (currencyIn === 'in') {
        dispatch({
          type: CURRENCIES_IN_GET,
          payload: response.data?.data?.attributes
        })
      } else if (currencyIn === 'out') {
        dispatch({
          type: CURRENCIES_OUT_GET,
          payload: response.data?.data?.attributes
        })
      } else {
        dispatch({
          type: CURRENCIES_GET,
          payload: response.data?.data?.attributes
        })
      }
    })
    .catch((err) => {
      if (err.code === 'ERR_NETWORK') {
        dispatch({
          type: SET_MAINTENANCE_MODE,
          payload: true
        })
      } else {
        dispatch({
          type: CURRENCIES_GET,
          payload: []
        })
        dispatch({
          type: SET_MAINTENANCE_MODE,
          payload: false
        })
        setTimeout(() => currenciesGet(currencyIn)(dispatch, getState), 1000)
        dispatch({
          type: GET_ERRORS,
          payload: { transaction: err.response }
        })
      }
    })
}

export const getCustomerBeneficiaries
  = (page = 0, limit = 56, search = undefined, isCompany = undefined, filterByImportEW = undefined) =>
    (dispatch, getState) => {
      dispatch({ type: BENEFICIARIES_GET_REQUEST })
      beneficiariesApiInstance
        .customersCustomerIdBeneficiariesGet(
          getState().login.customer_id,
          page,
          limit,
          getState().lang.lang,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          search,
          isCompany,
          filterByImportEW
        )
        .then((response) => {
          dispatch({
            type: BENEFICIARIES_GET,
            payload: {
              data: response.data,
              page,
              limit,
              search
            }
          })
        })
        .catch((err) => {
          dispatch({ type: BENEFICIARIES_GET, payload: [] })
          dispatch({
            type: GET_ERRORS,
            payload: { transaction: err.response }
          })
        })
    }

export const setSelectedBeneficiary = (beneficiary) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_BENEFICIARY,
    payload: beneficiary
  })
}

export const productsGet = (filterCurrencyIn, filterCurrencyOut, filterProductType) => (dispatch, getState) => {
  if (getState().login.customer_id) {
    apiInstance
      .customersCustomerIdProductsLoggedGet(
        getState().login.customer_id,
        filterCurrencyIn,
        filterCurrencyOut,
        getState().lang.lang,
        filterProductType,
        'ppi3'
      )
      .then((response) => {
        dispatch({ type: PRODUCTS_GET, payload: response.data })
      })
      .catch((err) => {
        setTimeout(() => productsGet(filterCurrencyIn, filterCurrencyOut, filterProductType)(dispatch, getState), 1000)
        dispatch({
          type: GET_ERRORS,
          payload: { transaction: err.response }
        })
      })
  } else {
    apiInstance
      .productsGet(filterCurrencyIn, filterCurrencyOut, getState().lang.lang, filterProductType, 'ppi3')
      .then((response) => {
        dispatch({ type: PRODUCTS_GET, payload: response.data })
      })
      .catch((err) => {
        setTimeout(() => productsGet(filterCurrencyIn, filterCurrencyOut, filterProductType)(dispatch, getState), 1000)
        dispatch({
          type: GET_ERRORS,
          payload: { transaction: err.response?.data }
        })
      })
  }
}

export const sendReceiptMail = () => (dispatch, getState) => {
  apiInstance
    .customersCustomerIdTransactionsTransactionIdReceiptGet(
      getState().login.customer_id,
      getState().transaction.transaction.id,
      getState().lang.lang
    )
    .then((response) => {
      dispatch({ type: SEND_CONFIRMATION_EMAIL, payload: response.data })
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: { transaction: err.response }
      })
    })
}

export const beneficiaryDelete = (beneficiaryId) => (dispatch) => {
  dispatch({
    type: BENEFICIARY_DELETE,
    payload: { beneficiaryId }
  })
}

export const beneficiaryVerifyPost = () => (dispatch, getState) => {
  const data = {
    data: {
      id: getState().transaction.beneficiaryToVerify,
      attributes: {
        customerId: getState().login.customer_id
      }
    }
  }
  beneficiariesApiInstance
    .customersCustomerIdBeneficiariesBeneficiaryIdVerifyPost(
      getState().login.customer_id,
      getState().transaction.beneficiaryToVerify,
      data,
      getState().lang.lang
    )
    .then((response) => {
      dispatch({
        type: BENEFICIARY_VERIFY_POST,
        payload: {
          beneficiaryId: getState().transaction.beneficiaryToVerify,
          data: response.data
        }
      })
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: { transaction: err.response }
      })
    })
}

export const beneficiaryVerifyPath = (token) => (dispatch, getState) => {
  const data = {
    data: {
      type: 'beneficiaries',
      id: getState().transaction.beneficiaryToVerify,
      attributes: {
        code: token
      }
    }
  }
  beneficiariesApiInstance
    .customersCustomerIdBeneficiariesBeneficiaryIdVerifyPatch(
      getState().login.customer_id,
      getState().transaction.beneficiaryToVerify,
      data,
      getState().lang.lang
    )
    .then((response) => {
      dispatch({
        type: BENEFICIARY_VERIFY_PATCH,
        payload: {
          beneficiaryId: getState().transaction.beneficiaryToVerify,
          data: response.data
        }
      })
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: { transaction: err.response }
      })
    })
}

// @param ${Number} surveryType 1- after trx | 2 - after rpi
export const getSurvey = (surveyType) => (dispatch, getState) => {
  surveysApiInstance
    .surveysSurveyIdGet(surveyType, getState().lang.lang)
    .then((response) => {
      dispatch({
        type: SURVEY_GET,
        payload: { survey: response.data.data.attributes }
      })
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: { transaction: err.response }
      })
    })
}

export const postAnswers = (answers, message, transferTypeId) => (dispatch, getState) => {
  const data = {
    data: {
      type: 'surveys',
      attributes: {
        customerId: getState().login.customer_id,
        transferTypeId: getState().transaction.products[transferTypeId].id,
        transactionId: getState().transaction.transaction.id,
        message,
        surveyAnswers: answers
      }
    }
  }
  surveysApiInstance
    .surveysTrxIdAnswersPost(getState().transaction.transaction.id, data, getState().lang.lang)
    .then(() => {
      dispatch({
        type: ANSWERS_POST,
        payload: {}
      })
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: { transaction: err.response }
      })
    })
}

export const promotionalCodesGet = (productID, filterCode) => (dispatch, getState) => {
  dispatch({ type: PROMOTION_CODES_GET_REQUEST })
  apiInstance
    .customersCustomerIdPromotionCodesGet(getState().login.customer_id, getState().lang.lang, productID, filterCode)
    .then((response) => {
      dispatch({
        type: PROMOTION_CODES_GET,
        payload: response.data?.data?.attributes
      })
    })
    .catch((err) => {
      dispatch({
        type: PROMOTION_CODES_GET,
        payload: []
      })
      dispatch({
        type: GET_ERRORS,
        payload: { transaction: err.response }
      })
    })
}

export const promotionalCodeSet = (promocodeData) => (dispatch) => {
  dispatch({
    type: PROMOTION_CODE_SET,
    payload: promocodeData
  })
}

export const getTransactionPurposeValues = () => (dispatch, getState) => {
  apiInstance
    .customersCustomerIdTransactionsDictionariesTransactionPurposesGet(getState().login.customer_id, getState().lang.lang)
    .then((response) => {
      const responseData = response.data?.data?.attributes;
      const data = {
        transactionPurpose: {
          input: {
            id: 'transactionPurpose',
            name: 'Transaction Purpose',
            nameEn: 'Transaction Purpose',
            namePl: 'Cel transakcji'
          },
          options: responseData?.map((item) => ({
            value: item.id,
            label: item.name,
            labelPl: item.namePl,
            labelEn: item.nameEn
          }))
        }
      }
      dispatch({ type: GET_TRANSACTION_PURPOSE_DICTIONARY, payload: data })
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: { transactionPurposeValues: err.response?.data }
      })
    })
}

export const setTransactionStepId = (transactionStep) => (dispatch) => {
  dispatch({
    type: SET_TRANSACTION_STEP_ID,
    payload: transactionStep
  })
}

export const transactionSourceOfFundsGet = () => (dispatch, getState) => {
  amlApiInstance
    .customersCustomerIdTransactionsDictionariesSourceOfFundsGet(getState().login.customer_id, getState().lang.lang)
    .then((response) => {
      dispatch({
        type: GET_TRANSACTION_SOURCE_OF_FUNDS,
        payload: response.data?.data?.attributes
      })
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: { transaction: err.response }
      })
    })
}

export const getTransactionSourceOfFundsCategory = () => (dispatch, getState) => {
  amlApiInstance
    .customersCustomerIdTransactionsDictionariesSourceOfFundsCategoryGet(
      getState().login.customer_id, getState().lang.lang
    )
    .then((response) => {
      dispatch({
        type: GET_TRANSACTION_SOURCE_OF_FUNDS_CATEGORY,
        payload: response.data?.data?.attributes
      })
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: { transaction: err.response }
      })
    })
}
