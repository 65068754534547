import { combineReducers } from 'redux'
import notification from './notification'
import errors from './errors'
import registration from './registration'
import login from './login'
import transaction from './transaction'
import cards from './cards'
import transactionHistory from './transactionHistory'
import customerAccount from './customerAccount'
import agents from './agents'
import contact from './contact'
import tracking from './tracking'
import calendar from './calendar'
import customerSources from './customerSources'
import customerSource from './customerSource'
import cardVerification from './cardVerification'
import repeatPayments from './repeatPayments'
import blogs from './blog'
import b2bBlog from './b2bBlog'
import lang from './lang'
import cms from './cms'
import visibility from './visibility'
import charity from './charity'
import loading from './loading'
import location from './location'
import aml from './aml'
import survey from './survey'
import beneficiaries from './beneficiaries'
import screenGuidance from './screenGuidance'
import api from './api'

export default combineReducers({
  errors,
  notification,
  registration,
  login,
  transaction,
  cards,
  transactionHistory,
  customerAccount,
  agents,
  contact,
  tracking,
  calendar,
  customerSources,
  customerSource,
  cardVerification,
  repeatPayments,
  blogs,
  b2bBlog,
  lang,
  cms,
  visibility,
  charity,
  loading,
  location,
  aml,
  survey,
  beneficiaries,
  screenGuidance,
  api
})
