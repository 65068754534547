import * as OneMoneyMailMobileApi from 'omm-rest-api-client'
import axiosInstance, { apiUrl } from '../config/api'

import {
  ACCOUNT_ACTIVATE,
  GET_COUNTRIES_REQUEST,
  GET_CUSTOMER_CARDS,
  GET_CUSTOMER_COUNTRIES,
  GET_CUSTOMER_DATA,
  GET_ERRORS,
  GET_REFFERAL_LINK,
  LIMIT_GET,
  PATCH_CUSTOMER_ACCEPTATIONS,
  PATCH_CUSTOMER_ADDRESS,
  PATCH_CUSTOMER_PASSWORD,
  POST_CUSTOMER_PHONE,
  GET_FINANCIAL_DATA,
  PATCH_FINANCIAL_DATA,
  ID_SCAN_URL_GET,
  SET_ID_SCAN_COMPLETE_STATUS
} from './types'

const apiInstance = new OneMoneyMailMobileApi.CustomerAccountApi({}, apiUrl, axiosInstance)
const limitApiInstance = new OneMoneyMailMobileApi.LimitApi({}, apiUrl, axiosInstance)
const paymentCardsApiInstance = new OneMoneyMailMobileApi.PaymentCardsApi({}, apiUrl, axiosInstance)
const countriesApiInstance = new OneMoneyMailMobileApi.CountriesApi({}, apiUrl, axiosInstance)
const scanIdApiInstance = new OneMoneyMailMobileApi.ScanIdApi({}, apiUrl, axiosInstance)

export const referralGet = () => (dispatch, getState) => {
  apiInstance
    .customersCustomerIdReferralLinkGet(getState().login.customer_id, getState().lang.lang)
    .then((response) => {
      dispatch({
        type: GET_REFFERAL_LINK,
        payload: response.data
      })
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: { customerAccount: err.response?.data }
      })
    })
}

export const customerGet = (customerId) => (dispatch, getState) => {
  const id = customerId || getState().login.customer_id;
  const currentLang = getState().lang.lang;

  apiInstance
    .customersCustomerIdGet(id, currentLang)
    .then((response) => {
      const customerData = response.data;

      dispatch({ type: GET_CUSTOMER_DATA, payload: customerData });

      return customerData;
    })
    .then((customerData) => {
      const customerLanguage = customerData?.data?.attributes?.customerDetail?.language;

      if (currentLang === customerLanguage) {
        return;
      }

      apiInstance
        .customersCustomerIdLanguagePost(id, {
          data: {
            type: 'customers',
            attributes: {
              language: currentLang
            }
          }
        })
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: { customerAccount: err.response?.data }
      });
    });
}

export const customerAcceptancePatch
  = (
    confirmationOfTheTransactionSmsAgreement,
    confirmationOfTheTransactionEmailAgreement,
    marketingSmsAgreement,
    marketingEmailAgreement,
    marketingNotificationAgreement
  ) =>
    (dispatch, getState) => {
      const data = {
        data: {
          type: 'customers',
          attributes: {
            confirmationOfTheTransactionSmsAgreement,
            confirmationOfTheTransactionEmailAgreement,
            marketingSmsAgreement,
            marketingEmailAgreement,
            marketingNotificationAgreement
          }
        }
      }

      apiInstance
        .customersCustomerIdPatch(getState().login.customer_id, data, getState().lang.lang)
        .then((response) => {
          dispatch({ type: PATCH_CUSTOMER_ACCEPTATIONS, payload: response.data })
        })
        .catch((err) => {
          dispatch({
            type: GET_ERRORS,
            payload: { customerAccount: err.response?.data }
          })
        })
    }

export const customerPasswordPatch = (oldPassword, newPassword, newPasswordRepeat) => (dispatch, getState) => {
  const data = {
    data: {
      type: 'customers',
      id: getState().login.customer_id,
      attributes: {
        oldPassword,
        newPassword,
        newPasswordRepeat
      }
    }
  }

  apiInstance
    .customersCustomerIdPasswordPatch(getState().login.customer_id, data, getState().lang.lang)
    .then((response) => {
      dispatch({ type: PATCH_CUSTOMER_PASSWORD, payload: response.data })
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: { customerAccount: err.response?.data }
      })
    })
}

export const customerAddressPatch
  = (street, houseNumber, buildingName, postCode, cityName, countryCode) => (dispatch, getState) => {
    const data = {
      data: {
        type: 'customers',
        attributes: {
          customerAddress: {
            street,
            houseNumber,
            buildingName,
            postCode,
            cityName,
            countryCode
          }
        }
      }
    }

    apiInstance
      .customersCustomerIdAddressPatch(getState().login.customer_id, data, getState().lang.lang)
      .then(() => {
        dispatch({
          type: PATCH_CUSTOMER_ADDRESS,
          payload: data.data.attributes.customerAddress
        })
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: { customerAccount: err.response?.data }
        })
      })
  }

export const customerPhoneNumberChange = (prefixPhoneMobile, phoneMobile) => (dispatch, getState) => {
  const data = {
    data: {
      type: 'customers',
      attributes: {
        prefixPhoneMobile,
        phoneMobile
      }
    }
  }

  apiInstance
    .customersCustomerIdPhoneNumberPost(getState().login.customer_id, data, getState().lang.lang)
    .then((response) => {
      dispatch({ type: POST_CUSTOMER_PHONE, payload: response.data })
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: { customerAccount: err.response?.data }
      })
    })
}

export const getCountries = () => (dispatch, getState) => {
  dispatch({ type: GET_COUNTRIES_REQUEST })
  countriesApiInstance
    .countriesGet(getState().lang.lang, 'mobile')
    .then((response) => {
      dispatch({
        type: GET_CUSTOMER_COUNTRIES,
        payload: response.data?.data?.attributes
      })
    })
    .catch((err) => {
      setTimeout(() => getCountries()(dispatch, getState), 1000)
      dispatch({
        type: GET_ERRORS,
        payload: { customerAccount: err.response?.data }
      })
    })
}

export const getCustomerCards = (filer) => (dispatch, getState) => {
  paymentCardsApiInstance
    .customersCustomerIdPaymentCardsGet(getState().login.customer_id, getState().lang.lang, filer)
    .then((response) => {
      dispatch({ type: GET_CUSTOMER_CARDS, payload: response.data })
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: { customerAccount: err.response?.data }
      })
    })
}

export const verifyCustomer = (token, password, passwordRepeat) => (dispatch, getState) => {
  const data = {
    data: {
      type: 'customers',
      attributes: {
        token,
        password,
        passwordRepeat
      }
    }
  }
  apiInstance
    .customersAccountActivationPost(data, getState().lang.lang)
    .then((response) => {
      dispatch({ type: ACCOUNT_ACTIVATE, payload: response.data })
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: { customerAccount: err.response?.data }
      })
    })
}

export const getCustomerLimit = () => (dispatch, getState) => {
  limitApiInstance
    .customersCustomerIdLimitGet(getState().login.customer_id, getState().lang.lang)
    .then((response) => {
      dispatch({ type: LIMIT_GET, payload: response.data.data.attributes })
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: { customerAccount: err.response?.data }
      })
    })
}

export const getCustomerFinancialData = (id, lang) => (dispatch) => {
  apiInstance
    .customersCustomerIdFinancialGet(id, lang)
    .then((response) => {
      dispatch({ type: GET_FINANCIAL_DATA, payload: response?.data?.data?.attributes?.customerFinancial })
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response?.data?.customerFinancial
      })
    })
}

export const patchCustomerFinancialData = (data) => (dispatch, getState) => {
  apiInstance
    .customersCustomerIdFinancialPatch(getState().login.customer_id, data, getState().lang.lang)
    .then(() => {
      dispatch({ type: PATCH_FINANCIAL_DATA, payload: data })
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response?.data?.customerFinancial
      })
    })
}

export const getIdScanUrl = () => (dispatch, getState) => {
  scanIdApiInstance.idscanCustomerIdUrlGet(getState().login.customer_id, getState().lang.lang)
    .then((response) => {
      dispatch({ type: ID_SCAN_URL_GET, payload: response?.data?.data?.attributes?.url })
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response?.data
      })
    })
}

export const setCustomerGdprAgreement = () => (dispatch, getState) => {
  const data = {
    data: {
      type: 'customers',
      attributes: {
        gdprAgreement: true
      }
    }
  }
  apiInstance.customersCustomerIdGdprPatch(getState().login.customer_id, data, getState().lang.lang)
    .then(() => {
      dispatch(customerGet())
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response?.data
      })
    })
}

export const setIdScanCompleteStatus = (status) => (dispatch) => {
  dispatch({ type: SET_ID_SCAN_COMPLETE_STATUS, payload: status })
}

export const hideOnScreenGuidanceAction = (hideOnScreenGuidance) => (dispatch, getState) => {
  const data = {
    data: {
      type: 'customers',
      attributes: {
        hideOnScreenGuidance
      }
    }
  }
  const customerId = getState().login.customer_id

  apiInstance
    .customersCustomerIdHideOnScreenGuidancePost(customerId, data, getState().lang.lang)
    .then(() => dispatch(customerGet(customerId)))
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: { hideOnScreenGuidanceAction: err.response?.data }
      })
    })
}
