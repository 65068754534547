import { GET_TRACKING } from '../actions/types'

const initialState = {}

export default (state = initialState, action = { type: undefined, payload: undefined }) => {
  switch (action.type) {
    case GET_TRACKING:
      return {
        ...state,
        ...action.payload
      }

    default:
      return state
  }
}
