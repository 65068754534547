import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'

import appReducer from './reducers'

function logger() {
  return (next) => (action) => {
    // console.log('will dispatch', action)

    // Call the next dispatch method in the middleware chain.
    const returnValue = next(action)

    // console.log('state after dispatch', getState())

    // This will likely be the action itself, unless
    // a middleware further in chain changed it.
    return returnValue
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(appReducer, composeEnhancers(applyMiddleware(thunk, logger)))
export default store
