import axios from 'axios'
import {
  CMS_GET_ABOUT_REPEAT_PAYMENT_PAGE_CONTENT,
  CMS_GET_ABOUT_US_PAGE_CONTENT,
  CMS_GET_ADDRESS_PICKER_CONTENT,
  CMS_GET_BENEFICIARY_PAGE_CONTENT,
  CMS_GET_BRANCHES_CONTENT,
  CMS_GET_CALCULATOR_CONTENT,
  CMS_GET_CARD_LIST_CONTENT,
  CMS_GET_CONTACT_US_PAGE_CONTENT,
  CMS_GET_CUSTOMER_PAGE_CONTENT,
  CMS_GET_EXCHANGE_ALERT_PAGE_CONTENT,
  CMS_GET_EXTRA_SERVICES_PAGE_CONTENT,
  CMS_GET_FAQ_PAGE_CONTENT,
  CMS_GET_FEEDBACK_PAGE_CONTENT,
  CMS_GET_FOOTER_CONTENT,
  CMS_GET_HISTORY_PAGE_CONTENT,
  CMS_GET_LANDING_PAGE_CONTENT,
  CMS_GET_LOGIN_PAGE_CONTENT,
  CMS_GET_LOGOUT_PAGE_CONTENT,
  CMS_GET_MOBILE_APPLICATION_PAGE_CONTENT,
  CMS_GET_NAVBAR_CONTENT,
  CMS_GET_OPINIONS,
  CMS_GET_PRODUCTS,
  CMS_GET_REGISTER_PAGE_CONTENT,
  CMS_GET_REPEAT_PAYMENTS_LIST_PAGE_CONTENT,
  CMS_GET_SET_PASSWORD_PAGE_CONTENT,
  CMS_GET_TRACK_MODAL_CONTENT,
  CMS_GET_TRACKING_PAGE_CONTENT,
  CMS_GET_TRANSFER_CURRENCY_PAGE_CONTENT,
  CMS_GET_TRANSFER_EXPRESS_PAGE_CONTENT,
  CMS_GET_TRANSFER_PAGE_CONTENT,
  CMS_GET_TRANSFER_STANDARD_PAGE_CONTENT,
  CMS_GET_TRANSFER_TEN_MIN_PAGE_CONTENT,
  CMS_GET_TRANSFER_UK_PAGE_CONTENT,
  CMS_GET_UPLOAD_PAGE_CONTENT,
  CMS_GET_VIA_INTERNET_PAGE_CONTENT,
  CMS_GET_VIA_PHONELINE_PAGE_CONTENT,
  CMS_GET_WIDGETS_CONTENT,
  CMS_GET_NOT_FOUND_PAGE_CONTENT,
  CMS_GET_SPLASH_SCREEN_CONTENT,
  CMS_GET_CONFIRM_ACCOUNT_PAGE_CONTENT,
  CMS_GET_OPINION_COMPONENT_CONTENT,
  CMS_GET_BENEFICIARY_LIST_CONTENT,
  CMS_GET_PAYMENT_CARDS_LIST_CONTENT,
  CMS_GET_ACCOUNT_FUNCTIONALITY_CONTENT,
  CMS_GET_TOUR_COMPONENT_CONTENT,
  CMS_GET_FRAUD_AWARENESS_CONTENT,
  CMS_GET_FINANCIAL_MODAL_CONTENT,
  CMS_GET_PRIVACY_PAGE_CONTENT,
  CMS_GET_DELETE_ACCOUNT_PAGE_CONTENT,
  CMS_INCREASE_ERROR_COUNTER,
  CMS_RESET_ERRORS_COUNTER,
  CMS_GET_COOKIES_PAGE_CONTENT,
  CMS_GET_COOKIES_CONSENT_POP_UPS,
  CMS_GET_COMPLAINTS_PAGE_CONTENT,
  CMS_GET_TYPES_OF_TRANSFER_PAGE_CONTENT,
  CMS_GET_FAQ_WIDGET_CONTENT,
  CMS_GET_TERMS_PAGE_CONTENT,
  CMS_GET_B2B_PAGE_CONTENT,
  CMS_GET_TRANSFER_ALERT_CONTENT,
  CMS_GET_LTF_CONTENT,
  CMS_GET_PAYMENT_METHODS_CONTENT
} from './types'
import config from '../config/cms'

const api = axios.create({
  baseURL: config.url
})

const prepareLanguage = (lang) => (lang ? lang.replace('_', '-') : '')

const increaseErrorCounter = (err, dispatch) => {
  const isUnReachable = err?.response?.status === 401 || err.code === 'ERR_NETWORK'
  if (isUnReachable) {
    dispatch({ type: CMS_INCREASE_ERROR_COUNTER })
  }
}

const makeCMSRequest = (pageUrl, actionType, dispatch, getStateRef) => {
  const { lang } = getStateRef().lang
  const language = prepareLanguage(lang)
  const url = `api/${pageUrl}?pLevel&locale=${language}`

  api.get(url).then((response) => {
    dispatch({
      type: actionType,
      payload: response?.data?.data
    })
    dispatch({ type: CMS_RESET_ERRORS_COUNTER })
  })
    .catch((err) => {
      increaseErrorCounter(err, dispatch)
    })
}

export const cmsHeartBeatCheck = () => {
  const url = `api/${config.path.landingPage}?pLevel&locale=pl-PL`

  api.get(url).then(() => {
    window.location.reload()
  })
    .catch(() => {
    })
}

export const getLandingPageContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.landingPage, CMS_GET_LANDING_PAGE_CONTENT, dispatch, getState)
}

export const getLoginPageContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.loginPage, CMS_GET_LOGIN_PAGE_CONTENT, dispatch, getState)
}

export const getNavbarContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.navbar, CMS_GET_NAVBAR_CONTENT, dispatch, getState)
}

export const getFooterContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.footer, CMS_GET_FOOTER_CONTENT, dispatch, getState)
}

export const getCalculatorContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.calculator, CMS_GET_CALCULATOR_CONTENT, dispatch, getState)
}

export const getTransferPageContent = () => (dispatch, getState) => {
  const { lang } = getState().lang
  const language = prepareLanguage(lang)

  api.get(`api/${config.path.transferPage}?pLevel&locale=${language}`).then((response) => {
    dispatch({
      type: CMS_GET_TRANSFER_PAGE_CONTENT,
      payload: {
        data: response?.data?.data,
        lang
      }
    })
    dispatch({ type: CMS_RESET_ERRORS_COUNTER })
  })
    .catch((err) => {
      increaseErrorCounter(err, dispatch)
    })
}

export const getBranchesPageContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.branchesPage, CMS_GET_BRANCHES_CONTENT, dispatch, getState)
}

export const getExtraServicesPageContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.extraServices, CMS_GET_EXTRA_SERVICES_PAGE_CONTENT, dispatch, getState)
}

export const getHistoryPageContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.historyPage, CMS_GET_HISTORY_PAGE_CONTENT, dispatch, getState)
}

export const getFeedbackPageContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.feedbackPage, CMS_GET_FEEDBACK_PAGE_CONTENT, dispatch, getState)
}

export const getOpinions = (limit, page) => (dispatch, getStateRef) => {
  const { lang } = getStateRef().lang
  const language = prepareLanguage(lang)
  const url = !limit
    ? `api/${config.path.opinions}?pLevel&locale=${language}&sort=id:desc`
    : `api/${config.path.opinions}?pLevel&locale=${language}&sort=id:desc&pagination[page]=${
      page || 1
    }&pagination[pageSize]=${limit}`

  api.get(url).then((response) => {
    dispatch({
      type: CMS_GET_OPINIONS,
      payload: response.data
    })
    dispatch({ type: CMS_RESET_ERRORS_COUNTER })
  })
    .catch((err) => {
      increaseErrorCounter(err, dispatch)
    })
}

export const getAboutUsPageContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.aboutUsPage, CMS_GET_ABOUT_US_PAGE_CONTENT, dispatch, getState)
}

export const getTransferStandardPageContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.transferStandardPage, CMS_GET_TRANSFER_STANDARD_PAGE_CONTENT, dispatch, getState)
}

export const getTransferExpressPageContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.transferExpressPage, CMS_GET_TRANSFER_EXPRESS_PAGE_CONTENT, dispatch, getState)
}

export const getTransferTenMinPageContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.transferForNowPage, CMS_GET_TRANSFER_TEN_MIN_PAGE_CONTENT, dispatch, getState)
}

export const getComplaintsPageContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.complaintsPage, CMS_GET_COMPLAINTS_PAGE_CONTENT, dispatch, getState)
}

export const getMobileApplicationPageContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.mobileApplicationPage, CMS_GET_MOBILE_APPLICATION_PAGE_CONTENT, dispatch, getState)
}

export const getTransferCurrencyPageContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.transferCurrency, CMS_GET_TRANSFER_CURRENCY_PAGE_CONTENT, dispatch, getState)
}

export const getContactUsPageContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.contactUsPage, CMS_GET_CONTACT_US_PAGE_CONTENT, dispatch, getState)
}

export const getCustomerPageContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.customerDetail, CMS_GET_CUSTOMER_PAGE_CONTENT, dispatch, getState)
}

export const getFaqPageContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.faqPage, CMS_GET_FAQ_PAGE_CONTENT, dispatch, getState)
}

export const getExchangeAlertPageContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.exchangeAlertPage, CMS_GET_EXCHANGE_ALERT_PAGE_CONTENT, dispatch, getState)
}

export const getRPIPageContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.repeatPaymentPage, CMS_GET_REPEAT_PAYMENTS_LIST_PAGE_CONTENT, dispatch, getState)
}

export const getRegisterPageContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.registerPage, CMS_GET_REGISTER_PAGE_CONTENT, dispatch, getState)
}

export const getAddressPickerContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.addressPicker, CMS_GET_ADDRESS_PICKER_CONTENT, dispatch, getState)
}

export const getUploadPageContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.uploadPage, CMS_GET_UPLOAD_PAGE_CONTENT, dispatch, getState)
}

export const getCardListContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.cardList, CMS_GET_CARD_LIST_CONTENT, dispatch, getState)
}

export const getBeneficiaryPageContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.beneficiaryPage, CMS_GET_BENEFICIARY_PAGE_CONTENT, dispatch, getState)
}

export const getWidgetsContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.widgets, CMS_GET_WIDGETS_CONTENT, dispatch, getState)
}

export const getViaInternetPageContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.viaInternet, CMS_GET_VIA_INTERNET_PAGE_CONTENT, dispatch, getState)
}

export const getViaPhonelinePageContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.viaPhoneline, CMS_GET_VIA_PHONELINE_PAGE_CONTENT, dispatch, getState)
}

export const getAboutRepeatPaymentPageContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.aboutRepeatPayments, CMS_GET_ABOUT_REPEAT_PAYMENT_PAGE_CONTENT, dispatch, getState)
}

export const getTrackingPageContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.trackingPage, CMS_GET_TRACKING_PAGE_CONTENT, dispatch, getState)
}

export const getTrackingModalContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.trackModal, CMS_GET_TRACK_MODAL_CONTENT, dispatch, getState)
}

export const getTransferUkContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.transferUk, CMS_GET_TRANSFER_UK_PAGE_CONTENT, dispatch, getState)
}

export const getSetPasswordContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.setPasswordPage, CMS_GET_SET_PASSWORD_PAGE_CONTENT, dispatch, getState)
}

export const getProducts = () => (dispatch, getState) => {
  const language = prepareLanguage(getState()?.lang?.lang)
  api.get(`api/${config.path.getProducts}?pLevel&locale=${language}`).then((response) => {
    dispatch({
      type: CMS_GET_PRODUCTS,
      payload: response.data?.data
    })
    dispatch({ type: CMS_RESET_ERRORS_COUNTER })
  })
    .catch((err) => {
      increaseErrorCounter(err, dispatch)
    })
}

export const getNotFoundPageContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.notFoundPage, CMS_GET_NOT_FOUND_PAGE_CONTENT, dispatch, getState)
}
export const getSplashScreenContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.splashScreen, CMS_GET_SPLASH_SCREEN_CONTENT, dispatch, getState)
}
export const getConfirmAccountPageContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.confirmAccountPage, CMS_GET_CONFIRM_ACCOUNT_PAGE_CONTENT, dispatch, getState)
}
export const getOpinionComponentContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.opinionComponent, CMS_GET_OPINION_COMPONENT_CONTENT, dispatch, getState)
}
export const getBeneficiaryListContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.beneficiaryList, CMS_GET_BENEFICIARY_LIST_CONTENT, dispatch, getState)
}
export const getPaymentCardsListContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.paymentCardList, CMS_GET_PAYMENT_CARDS_LIST_CONTENT, dispatch, getState)
}
export const getAccountFunctionalityContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.accountFunctionality, CMS_GET_ACCOUNT_FUNCTIONALITY_CONTENT, dispatch, getState)
}
export const getTourComponentContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.tourComponent, CMS_GET_TOUR_COMPONENT_CONTENT, dispatch, getState)
}

export const getFraudAwarenessContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.fraudAwareness, CMS_GET_FRAUD_AWARENESS_CONTENT, dispatch, getState)
}
export const getFinancialModalContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.financialModal, CMS_GET_FINANCIAL_MODAL_CONTENT, dispatch, getState)
}
export const getPrivacyPageContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.privacyPage, CMS_GET_PRIVACY_PAGE_CONTENT, dispatch, getState)
}
export const getDeleteAccountPageContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.deleteAccountPage, CMS_GET_DELETE_ACCOUNT_PAGE_CONTENT, dispatch, getState)
}
export const getCookiesPageContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.cookiesPage, CMS_GET_COOKIES_PAGE_CONTENT, dispatch, getState)
}

export const getCookiesConsentPopUpsContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.cookiesConsentPopUps, CMS_GET_COOKIES_CONSENT_POP_UPS, dispatch, getState)
}

export const getTypesOfTransferPageContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.typesOfTransferPage, CMS_GET_TYPES_OF_TRANSFER_PAGE_CONTENT, dispatch, getState)
}

export const getFaqWidgetContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.faqWidget, CMS_GET_FAQ_WIDGET_CONTENT, dispatch, getState)
}

export const getLogoutPageContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.logoutPage, CMS_GET_LOGOUT_PAGE_CONTENT, dispatch, getState)
}

export const getTermsPageContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.termsPage, CMS_GET_TERMS_PAGE_CONTENT, dispatch, getState)
}

export const getB2bPageContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.b2bPage, CMS_GET_B2B_PAGE_CONTENT, dispatch, getState)
}

export const getTransferAlertContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.transferAlert, CMS_GET_TRANSFER_ALERT_CONTENT, dispatch, getState)
}

export const getLtfContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.ltfForm, CMS_GET_LTF_CONTENT, dispatch, getState)
}

export const getPaymentMethodsContent = () => (dispatch, getState) => {
  makeCMSRequest(config.path.paymentMethods, CMS_GET_PAYMENT_METHODS_CONTENT, dispatch, getState)
}
