import { GET_B2B_BLOG_POSTS_REQUEST, GET_B2B_BLOG_POST } from '../actions/types'

const initialState = {
  blogs: [],
  blogsLoading: true
}

export default (state = initialState, action = { type: undefined, payload: undefined }) => {
  switch (action.type) {
    case GET_B2B_BLOG_POST:
      return {
        ...state,
        blogs: action.payload,
        blogsLoading: false
      }

    case GET_B2B_BLOG_POSTS_REQUEST:
      return {
        ...state,
        blogsLoading: true
      }

    default:
      return {
        ...state
      }
  }
}
