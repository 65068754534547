import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'react-bootstrap'
import * as OneMoneyMailMobileApi from 'omm-rest-api-client'
import axiosInstance, { apiUrl } from '../config/api'
import { logout, setRefreshToken, setUser } from '../actions/login'
import { customerGet } from '../actions/customerAccount'
import { getPath } from '../MultilingualRouter/MultilingualRouter'
import Pages from '../Enum/Pages'

const apiInstance = new OneMoneyMailMobileApi.CustomerLoginApi({}, apiUrl, axiosInstance)

const PrivateRoute = ({ children }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)

  const refreshToken = useSelector((state) => state.login?.refresh_token)
  const authVerified = sessionStorage.getItem('authVerified')
  const lang = useSelector((state) => state.lang.lang)
  const customerData = useSelector((state) => state.customerAccount?.customer)
  const newUserData = sessionStorage.getItem('userData')
  const customerId = JSON.parse(newUserData)?.customer_id

  const logoutAndRedirectToLoginPage = () => {
    navigate(getPath(Pages.loginPage, lang))
    logout(lang, navigate)
  }

  useEffect(() => {
    if (!refreshToken) {
      try {
        if (!customerId) {
          logoutAndRedirectToLoginPage()
        }
      } catch {
        logoutAndRedirectToLoginPage()
      }
      dispatch(setUser(JSON.parse(newUserData)))
    }

    if (customerId !== null && customerId) {
      if (!customerData) {
        dispatch(customerGet())
      }

      const data = {
        refresh_token: refreshToken || sessionStorage.getItem('refresh_token'),
        grant_type: 'refresh_token',
        client_id: 'ppi3'
      }
      apiInstance
        .customersRefreshTokenPost(lang, data)
        .then((res) => {
          dispatch(setRefreshToken({ ...res.data, customer_id: customerId }))
        })
        .catch(() => {
          logoutAndRedirectToLoginPage()
        })
        .finally(() => setLoading(false))
    }
  }, [dispatch, navigate, lang, customerId]) // eslint-disable-line

  useEffect(() => {
    if (!authVerified) {
      navigate(getPath(Pages.loginPage, lang))
    }
  }, [authVerified, lang, navigate])

  if (loading) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    )
  }
  return children
}

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired
}

export default PrivateRoute
