/**
 *  @param {object | undefined | null} object
 *  @return boolean
 */
const isEmpty = (object) =>
  object === null
  || object === undefined
  || (object && Object.keys(object).length === 0 && Object.getPrototypeOf(object) === Object.prototype)

export default isEmpty
