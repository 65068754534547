const Pages = {
  index: 'index',
  aboutRepeatPayments: 'aboutRepeatPayments',
  aboutUsPage: 'aboutUsPage',
  agentsPage: 'agentsPage',
  beneficiaryListPage: 'beneficiaryListPage',
  branchesPage: 'branchesPage',
  charityPage: 'charityPage',
  confirmAccountPage: 'confirmAccountPage',
  contactUsPage: 'contactUsPage',
  customerPage: 'customerPage',
  extraServicesPage: 'extraServicesPage',
  faqPage: 'faqPage',
  feedbackPage: 'feedbackPage',
  historyPage: 'historyPage',
  loginPage: 'loginPage',
  logoutPage: 'logoutPage',
  mobileApplicationPage: 'mobileApplicationPage',
  paymentCards: 'paymentCards',
  registerPage: 'registerPage',
  repeatPayments: 'repeatPayments',
  resetPassword: 'resetPassword',
  rpiAddPage: 'rpiAddPage',
  trackingPage: 'trackingPage',
  transferCurrencyPage: 'transferCurrencyPage',
  transferExpressPage: 'transferExpressPage',
  transferPage: 'transferPage',
  transferStandardPage: 'transferStandardPage',
  transferTenMinPage: 'transferTenMinPage',
  complaintsPage: 'complaintsPage',
  transferUk: 'transferUk',
  trxOptionsPage: 'trxOptionsPage',
  uploadPage: 'uploadPage',
  viaInternetPage: 'viaInternetPage',
  viaPhonelinePage: 'viaPhonelinePage',
  notFound: 'notFound',
  fraudAwarenessPage: 'fraudAwarenessPage',
  privacyPage: 'privacyPage',
  accountDeletePage: 'accountDeletePage',
  cookiesPage: 'cookiesPage',
  verificationPage: 'verificationPage',
  idScanPage: 'idScanPage',
  idScanReDirect: 'idScanReDirect',
  typesOfTransferPage: 'typesOfTransferPage',
  termsPage: 'termsPage',
  forBusinessPage: 'forBusinessPage',
  globalPaymentRedirect: 'globalPaymentRedirect'
}

export default Pages
