import store from '../store'
import paths from './MultilingualRouterPaths'

const getLanguageUrlWithParameter = (pathName, lang) => {
  const urlArray = window.location.pathname.split('/')
  const pathArray = pathName?.[lang]?.replace(/:\w+/, ':var')?.split('/')
  return pathArray?.map((pathKey, index) => (pathKey === ':var' ? urlArray[index] : pathKey)).join('/')
}

export const getPath = (page, lang = null) => {
  const reduxState = store.getState()
  const language = reduxState?.lang?.lang
  const pathLang = lang || language
  const path = paths[page]?.[pathLang]?.includes(':')
    ? getLanguageUrlWithParameter(paths[page], lang)
    : paths[page]?.[pathLang]

  return path || paths.index[pathLang]
}

/**
 * @param {string } url Current URL
 * @return {{page: string | undefined, lang: string | undefined}} Page and language
 */
export const parseUrl = (url) => {
  let lang
  const page = Object.entries(paths)
    .find(([, languagePaths]) => {
      const languages = Object.entries(languagePaths).filter(([pathLang, path]) => {
        let matchedPath = path
        let formattedUrl = url

        if (path.includes(':')) {
          const pathWithVars = path.replace(/:\w+/, ':var')
          const urlArray = url?.split('/')
          const pathArray = pathWithVars?.split('/')

          if (urlArray?.length === pathArray?.length) {
            urlArray.forEach((key, index) => {
              if (pathArray[index] === ':var') {
                urlArray[index] = ':var'
                const urlWithVars = urlArray.join('/')
                if (urlWithVars === pathWithVars) {
                  matchedPath = pathWithVars
                  formattedUrl = urlWithVars
                  lang = pathLang
                }
              }
            })
          }
        }

        return matchedPath === formattedUrl
      })

      // Only one match, so set the language
      if (languages.length === 1) {
        [lang] = languages.shift()
        return true
      }

      return languages.length !== 0
    })
    ?.shift()
  return { page, lang }
}
