import {
  GET_REFFERAL_LINK,
  GET_CUSTOMER_DATA,
  PATCH_CUSTOMER_ACCEPTATIONS,
  PATCH_CUSTOMER_PASSWORD,
  PATCH_CUSTOMER_ADDRESS,
  POST_CUSTOMER_PHONE,
  GET_CUSTOMER_COUNTRIES,
  GET_CUSTOMER_CARDS,
  ACCOUNT_ACTIVATE,
  LIMIT_GET,
  GET_COUNTRIES_REQUEST,
  GET_FINANCIAL_DATA,
  PATCH_FINANCIAL_DATA,
  ID_SCAN_URL_GET,
  SET_ID_SCAN_COMPLETE_STATUS,
  RESET_APP_DATA
} from '../actions/types'

const initialState = {
  countries: [],
  limit: 0,
  remainingLimit: 0,
  countriesLoaded: false,
  idScanCompleteStatus: false,
  hasFinancialDataFetched: false
}

export default (state = initialState, action = { type: undefined, payload: undefined }) => {
  switch (action.type) {
    case GET_REFFERAL_LINK:
      return {
        ...state,
        refferal_link: action.payload.data
      }

    case GET_CUSTOMER_DATA:
      return {
        ...state,
        customer: action.payload.data,
        patch_applications_status: false,
        patch_password_status: false,
        patch_address_status: false,
        post_customer_phone_status: false
      }

    case PATCH_CUSTOMER_ACCEPTATIONS:
      return {
        ...state,
        patch_applications_status: true
      }

    case PATCH_CUSTOMER_PASSWORD:
      return {
        ...state,
        patch_password_status: true
      }

    case PATCH_CUSTOMER_ADDRESS:
      return {
        ...state,
        customer: { ...state.customer, customerAddress: action.payload },
        patch_address_status: true
      }

    case POST_CUSTOMER_PHONE:
      return {
        ...state,
        post_customer_phone_status: true
      }

    case GET_CUSTOMER_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
        countriesLoaded: true
      }

    case GET_COUNTRIES_REQUEST:
      return {
        ...state,
        countriesLoaded: false
      }

    case GET_CUSTOMER_CARDS:
      return {
        ...state,
        cards: action.payload.data
      }

    case ACCOUNT_ACTIVATE:
      return {
        ...state,
        verification_success: true
      }

    case LIMIT_GET:
      return {
        ...state,
        limit: action.payload.limit,
        remainingLimit: action.payload.remainingLimit
      }

    case GET_FINANCIAL_DATA:
      return {
        ...state,
        financialData: action.payload,
        hasFinancialDataFetched: true
      }

    case PATCH_FINANCIAL_DATA:
      return {
        ...state,
        financialData: action.payload
      }

    case ID_SCAN_URL_GET:
      return {
        ...state,
        idScanUrl: action.payload
      }

    case SET_ID_SCAN_COMPLETE_STATUS:
      return {
        ...state,
        idScanCompleteStatus: action.payload
      }

    case RESET_APP_DATA:
      return {}

    default:
      return {
        ...state
      }
  }
}
