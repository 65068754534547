import { SET_MAINTENANCE_MODE } from '../actions/types'

const initialState = {
  maintenance: false
}

export default (state = initialState, action = { type: undefined, payload: undefined }) => {
  switch (action.type) {
    case SET_MAINTENANCE_MODE:
      return {
        ...state,
        maintenance: action.payload
      }

    default:
      return state
  }
}
