import { SET_REGISTER_DATA, GET_COUNTRIES } from '../actions/types'

const initialState = {
  registerData: {},
  addressLoading: false,
  countries: [],
  address: {}
}
export default (state = initialState, action = { type: undefined, payload: undefined }) => {
  switch (action.type) {
    case SET_REGISTER_DATA:
      return {
        ...state,
        registerData: action.payload
      }

    case GET_COUNTRIES:
      return {
        ...state,
        countries: action.payload.data
      }

    default:
      return state
  }
}
