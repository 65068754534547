import React from 'react'
import ReactDOM from 'react-dom/client'
import ReactGA from 'react-ga4'
import { Provider } from 'react-redux'
import App from './App'
import * as serviceWorker from './serviceWorker'
import store from './store'
import { productsGet, currenciesGet } from './actions/transaction'

const loadRecaptcha = () => {
  const script = document.createElement('script')
  script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`
  script.async = true
  document.body.appendChild(script)
}

const loadGtag = () => {
  const script = document.createElement('script')
  script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID}`
  script.async = true
  document.body.appendChild(script)

  script.onload = () => {
    window.dataLayer = window.dataLayer || []
    function gtag() {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments)
    }
    gtag('js', new Date())
    gtag('config', process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID)
  }
}

const initializeGA = () => {
  ReactGA.initialize([
    {
      trackingId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID
    }
  ])
}

const getInitialRequests = () => {
  store.dispatch(currenciesGet('in'))
  store.dispatch(productsGet('2', '1', undefined))
}

loadRecaptcha()
loadGtag()
initializeGA()
getInitialRequests()

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <App />
  </Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
