import React, { useEffect } from 'react'
import { useLocation, useRoutes, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../actions/login'
import PrivateRoute from '../utils/PrivateRoute'
import paths from '../MultilingualRouter/MultilingualRouterPaths'
import Languages from '../Enum/Languages'
import { parseUrl, getPath } from '../MultilingualRouter/MultilingualRouter'
import { dispatchSetLocation } from '../actions/location'
import { setLanguage } from '../actions/lang'
import Pages from '../Enum/Pages'
import HelmetTag from '../utils/HelmetTag'

const LandingPage = React.lazy(() => import('../pages/LandingPage/LandingPage'))
const PageNotFound = React.lazy(() => import('../pages/PageNotFound'))
const LoginPage = React.lazy(() => import('../pages/LoginPage/LoginPage'))
const RegisterPage = React.lazy(() => import('../pages/RegisterPage/RegisterPage'))
const BranchesPage = React.lazy(() => import('../pages/BranchesPage/BranchesPage'))
const ExtraServicesPage = React.lazy(() => import('../pages/BranchesPage/ExtraServicesPage'))
const AboutUsPage = React.lazy(() => import('../pages/AboutUsPage/AboutUsPage'))
const FeedbackPage = React.lazy(() => import('../pages/FeedbackPage/FeedbackPage'))
const TransferStandardPage = React.lazy(() => import('../pages/TransferStandardPage/TransferStandardPage'))
const TransferExpressPage = React.lazy(() => import('../pages/TransferExpressPage/TransferExpressPage'))
const TransferTenMinPage = React.lazy(() => import('../pages/TransferTenMinPage/TransferTenMinPage'))
const ComplaintsPage = React.lazy(() => import('../pages/ComplaintsPage/ComplaintsPage'))
const MobileApplicationPage = React.lazy(() => import('../pages/MobileApplicationPage/MobileApplicationPage'))
const ViaInternetPage = React.lazy(() => import('../pages/ViaInternetPage/ViaInternetPage'))
const ViaPhonelinePage = React.lazy(() => import('../pages/ViaPhonelinePage/ViaPhonelinePage'))
const TransferCurrencyPage = React.lazy(() => import('../pages/TransferCurrencyPage/TransferCurrencyPage'))
const LogoutPage = React.lazy(() => import('../pages/LogoutPage/LogoutPage'))
const ContactUsPage = React.lazy(() => import('../pages/ContactUsPage/ContactUsPage'))
const AboutRepeatPayments = React.lazy(() => import('../pages/AboutRepeatPayments/AboutRepeatPayments'))
const FaqPage = React.lazy(() => import('../pages/FaqPage/FaqPage'))
const ConfirmAccountPage = React.lazy(() => import('../pages/ConfirmAccountPage/ConfirmAccountPage'))
const ResetPassword = React.lazy(() => import('../pages/ResetPassword/ResetPassword'))
const CharityPage = React.lazy(() => import('../pages/CharityPage/CharityPage'))
const TransferUk = React.lazy(() => import('../pages/TransferUk/TransferUk'))
const FraudAwarenessPage = React.lazy(() => import('../pages/FraudAwarenessPage/FraudAwarenessPage'))
const PrivacyPage = React.lazy(() => import('../pages/PrivacyPage/PrivacyPage'))
const CookiesPage = React.lazy(() => import('../pages/CookiesPage/CookiesPage'))
const TypesOfTransferPage = React.lazy(() => import('../pages/TypesOfTransferPage/TypesOfTransferPage'))
const TermsPage = React.lazy(() => import('../pages/TermsPage/TermsPage'))
const B2bPage = React.lazy(() => import('../pages/B2bPage/B2bPage'))
const GlobalPaymentStatusPage = React.lazy(() => import('../pages/GlobalPaymentStatusPage/GlobalPaymentStatusPage'))

// Authenticated Pages
const TransferPage = React.lazy(() => import('../pages/TransferPage/TransferPage'))
const HistoryPage = React.lazy(() => import('../pages/HistoryPage/HistoryPage'))
const CustomerPage = React.lazy(() => import('../pages/CustomerPage/CustomerPage'))
const BeneficiaryListPage = React.lazy(() => import('../pages/BeneficiaryListPage/BeneficiaryListPage'))
const RepeatPaymentAddPage = React.lazy(() => import('../pages/RepeatPaymentPage/RPIPaymentsPage'))
const RepeatPayments = React.lazy(() => import('../pages/RepeatPayments/RepeatPayments'))
const TrackingPage = React.lazy(() => import('../pages/TrackingPage/TrackingPage'))
const UploadPage = React.lazy(() => import('../pages/CustomerPage/Upload/UploadPage'))
const VerificationPage = React.lazy(() => import('../pages/CustomerPage/VerificationPage/VerificationPage'))
const IdScanPage = React.lazy(() => import('../pages/CustomerPage/VerificationPage/IdScanPage'))
const IdScanReDirect = React.lazy(() => import('../pages/CustomerPage/VerificationPage/IdScanReDirect'))
const AccountDeletePage = React.lazy(() => import('../pages/AccountDeletePage/AccountDeletePage'))

const App = () => {
  const pageLocation = useLocation()
  const dispatch = useDispatch()
  const userStatus = useSelector((state) => state?.errors?.customerAccount?.status)
  const currentLang = useSelector((state) => state?.lang?.lang)
  const navigate = useNavigate()

  if (userStatus === 401) {
    setTimeout(logout, 2500)
  }

  useEffect(() => {
    const { page, lang } = parseUrl(pageLocation.pathname)
    if (!page) {
      navigate(getPath(Pages.notFound))
      return
    }

    if (lang && currentLang !== lang) {
      dispatch(setLanguage(lang))
    }

    dispatchSetLocation(page, lang ?? currentLang, dispatch)
  }, [dispatch, currentLang, pageLocation.pathname, navigate])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' })
  }, [pageLocation])

  const languageRoutes = (path, element) =>
    Object.keys(Languages)?.map((language) => ({
      path: paths[path][Languages[language]],
      element: <><HelmetTag />{element}</>
    }))

  return useRoutes([
    ...languageRoutes(Pages.index, <LandingPage />),
    ...languageRoutes(Pages.loginPage, <LoginPage />),
    ...languageRoutes(Pages.logoutPage, <LogoutPage />),
    ...languageRoutes(Pages.registerPage, <RegisterPage />),
    ...languageRoutes(Pages.branchesPage, <BranchesPage agentPage={false} />),
    ...languageRoutes(Pages.agentsPage, <BranchesPage agentPage />),
    ...languageRoutes(Pages.extraServicesPage, <ExtraServicesPage />),
    ...languageRoutes(Pages.aboutUsPage, <AboutUsPage />),
    ...languageRoutes(Pages.transferStandardPage, <TransferStandardPage />),
    ...languageRoutes(Pages.feedbackPage, <FeedbackPage />),
    ...languageRoutes(Pages.transferExpressPage, <TransferExpressPage />),
    ...languageRoutes(Pages.transferTenMinPage, <TransferTenMinPage />),
    ...languageRoutes(Pages.complaintsPage, <ComplaintsPage />),
    ...languageRoutes(Pages.mobileApplicationPage, <MobileApplicationPage />),
    ...languageRoutes(Pages.viaInternetPage, <ViaInternetPage />),
    ...languageRoutes(Pages.viaPhonelinePage, <ViaPhonelinePage />),
    ...languageRoutes(Pages.transferCurrencyPage, <TransferCurrencyPage />),
    ...languageRoutes(Pages.contactUsPage, <ContactUsPage />),
    ...languageRoutes(Pages.aboutRepeatPayments, <AboutRepeatPayments />),
    ...languageRoutes(Pages.confirmAccountPage, <ConfirmAccountPage />),
    ...languageRoutes(Pages.faqPage, <FaqPage />),
    ...languageRoutes(Pages.resetPassword, <ResetPassword />),
    ...languageRoutes(Pages.charityPage, <CharityPage />),
    ...languageRoutes(Pages.transferUk, <TransferUk />),
    ...languageRoutes(Pages.trackingPage, <TrackingPage />),
    ...languageRoutes(Pages.fraudAwarenessPage, <FraudAwarenessPage />),
    ...languageRoutes(Pages.privacyPage, <PrivacyPage />),
    ...languageRoutes(Pages.cookiesPage, <CookiesPage />),
    ...languageRoutes(Pages.idScanReDirect, <IdScanReDirect />),
    ...languageRoutes(Pages.typesOfTransferPage, <TypesOfTransferPage />),
    ...languageRoutes(Pages.termsPage, <TermsPage />),
    ...languageRoutes(Pages.forBusinessPage, <B2bPage />),
    ...languageRoutes(Pages.globalPaymentRedirect, <GlobalPaymentStatusPage />),
    ...languageRoutes(
      Pages.transferPage,
      <PrivateRoute>
        <TransferPage />
      </PrivateRoute>
    ),
    ...languageRoutes(
      Pages.historyPage,
      <PrivateRoute>
        <HistoryPage />
      </PrivateRoute>
    ),
    ...languageRoutes(
      Pages.customerPage,
      <PrivateRoute>
        <CustomerPage step="dashboard" />
      </PrivateRoute>
    ),
    ...languageRoutes(
      Pages.uploadPage,
      <PrivateRoute>
        <UploadPage />
      </PrivateRoute>
    ),
    ...languageRoutes(
      Pages.verificationPage,
      <PrivateRoute>
        <VerificationPage />
      </PrivateRoute>
    ),
    ...languageRoutes(
      Pages.idScanPage,
      <PrivateRoute>
        <IdScanPage />
      </PrivateRoute>
    ),
    ...languageRoutes(
      Pages.beneficiaryListPage,
      <PrivateRoute>
        <BeneficiaryListPage />
      </PrivateRoute>
    ),
    ...languageRoutes(
      Pages.rpiAddPage,
      <PrivateRoute>
        <RepeatPaymentAddPage />
      </PrivateRoute>
    ),
    ...languageRoutes(
      Pages.repeatPayments,
      <PrivateRoute>
        <RepeatPayments />
      </PrivateRoute>
    ),
    ...languageRoutes(
      Pages.accountDeletePage,
      <PrivateRoute>
        <AccountDeletePage />
      </PrivateRoute>
    ),
    {
      path: '/*',
      element: <PageNotFound />
    }
  ])
}

export default App
