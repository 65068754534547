import {
  CURRENCIES_GET,
  CURRENCIES_IN_GET,
  CURRENCIES_OUT_GET,
  BENEFICIARY_DELETE,
  BENEFICIARY_PATCH,
  BENEFICIARY_VERIFY_PATCH,
  BENEFICIARY_VERIFY_POST,
  BENEFICIARIES_POST_PATCH_REQUEST,
  BENEFICIARIES_GET,
  SET_SELECTED_BENEFICIARY,
  PRODUCTS_GET,
  PROMOTION_CODES_GET,
  PROMOTION_CODES_GET_REQUEST,
  DELETE_CARD,
  SURVEY_GET,
  ANSWERS_POST,
  BENEFICIARIES_GET_REQUEST,
  ADD_BENEFICIARY,
  ADD_EW_BENEFICIARY,
  VERIFIED_NEW_BENEFICIARY,
  VERIFIED_NEW_EW_BENEFICIARY,
  BENEFICIARY_VERIFICATION_CANCEL,
  TRANSACTION_POST_LTF_CONFIRMATION_CANCEL,
  SURVEY_CANCEL,
  CURRENT_PRODUCT_SET,
  CLEAR_BENEFICIARIES,
  PROMOTION_CODE_SET,
  CALCULATOR_DATA,
  SET_TRANSACTION_STEP_ID,
  GET_TRANSACTION_SOURCE_OF_FUNDS,
  GET_TRANSACTION_SOURCE_OF_FUNDS_CATEGORY,
  GET_TRANSACTION_PURPOSE_DICTIONARY
} from '../actions/types'
import TransactionStepEnum from '../Enum/TransactionStepEnum'

const initialState = {
  currencies: [
    {
      id: 0,
      name: '',
      description: ''
    }
  ],
  currenciesIn: undefined,
  currenciesOut: undefined,
  products: [
    {
      alias: '',
      charge: 0, // float
      currencyIn: 0,
      currencyOut: 0,
      exchanges: [
        {
          maxAmount: 0,
          minAmount: 0,
          value: Number // float
        }
      ]
    }
  ],
  survey: {
    success: false
  },
  beneficiaries: [],
  promoCodes: undefined,
  promotionalCodesLoaded: false,
  beneficiariesLoaded: false,
  productsLoaded: false,
  currenciesLoaded: false,
  currentProduct: 15,
  beneficiaryEditedAdded: false,
  transactionPostedPatched: true,
  transactionPostedPatchedIndicator: false,
  beneficiaryToVerify: undefined,
  sagePayValidation: false,
  ltfModalShow: false,
  beneficiariesLast: 0,
  beneficiariesPageNumber: 1,
  beneficiariesPageLimit: 10,
  transactionStep: TransactionStepEnum.CALCULATOR
}
export default (state = initialState, action = { type: undefined, payload: undefined }) => {
  let beneficiaryFlags = new Set()
  switch (action.type) {
    case CURRENCIES_GET:
      return {
        ...state,
        currenciesLoaded: true,
        currencies: action.payload
      }

    case CURRENCIES_IN_GET:
      return {
        ...state,
        currenciesIn: action.payload
      }

    case CURRENCIES_OUT_GET:
      return {
        ...state,
        currenciesOut: action.payload
      }

    case PRODUCTS_GET:
      return {
        ...state,
        products: action.payload.data.attributes,
        defaultExchangeRate: action.payload.data.attributes?.[0]?.exchanges?.[0]?.value || 'Wystąpił błąd'
      }

    case BENEFICIARIES_GET_REQUEST:
      return {
        ...state,
        beneficiariesLoaded: false
      }

    case BENEFICIARIES_GET:
      beneficiaryFlags = new Set()
      return {
        ...state,
        beneficiariesLoaded: true,
        beneficiariesNull: !action?.payload?.data?.data?.attributes,
        beneficiaries: [
          ...(action.payload?.data?.links?.last?.match(/\d+/)[0] === '1' || action.payload.page === 1
            ? []
            : state.beneficiaries),
          ...(action?.payload?.data?.data?.attributes || [])
        ].filter((entry) => {
          if (beneficiaryFlags.has(entry.id)) {
            return false
          }
          beneficiaryFlags.add(entry.id)
          return true
        }),
        beneficiariesLast: action.payload?.data?.links?.last?.match(/\d+/)[0],
        beneficiariesPageNumber: action.payload.page,
        beneficiariesPageLimit: action.payload.limit,
        beneficiariesSearchText: action.payload.search
      }

    case SET_SELECTED_BENEFICIARY:
      return { ...state, selectedBeneficiary: action.payload }

    case CLEAR_BENEFICIARIES:
      return {
        ...state,
        beneficiariesNull: false,
        beneficiaries: []
      }

    case ADD_BENEFICIARY:
      return {
        ...state,
        addNewBeneficiary: action.payload,
        beneficiaries: action.payload ? [...state.beneficiaries, action.payload] : state.beneficiaries
      }

    case ADD_EW_BENEFICIARY:
      return { ...state, newEwBeneficiary: action.payload }

    case VERIFIED_NEW_BENEFICIARY:
      return { ...state, verifiedNewBeneficiary: action.payload }

    case VERIFIED_NEW_EW_BENEFICIARY:
      return { ...state, verifiedNewEwBeneficiary: action.payload }

    case BENEFICIARY_PATCH:
      return {
        ...state,
        beneficiaryEditedAdded: true,
        beneficiaries: state.beneficiaries?.map(
          (beneficiary) => (beneficiary.id === action.payload?.id ? action.payload?.attributes : beneficiary)
        )
      }

    case BENEFICIARIES_POST_PATCH_REQUEST:
      return {
        ...state,
        beneficiaryEditedAdded: false
      }

    case BENEFICIARY_VERIFICATION_CANCEL:
      return {
        ...state,
        beneficiaryToVerify: undefined
      }

    case TRANSACTION_POST_LTF_CONFIRMATION_CANCEL:
      return {
        ...state,
        ltfModalShow: false,
        transactionPostedPatchedIndicator: false
      }

    case BENEFICIARY_VERIFY_POST:
      return {
        ...state,
        tokenSent: true,
        beneficiaryVerified: false
      }

    case BENEFICIARY_VERIFY_PATCH:
      return {
        ...state,
        beneficiaryVerified: true
      }

    case BENEFICIARY_DELETE: {
      return {
        ...state,
        beneficiaries: state.beneficiaries.filter((beneficiary) => beneficiary.id !== action.payload.beneficiaryId)
      }
    }

    case DELETE_CARD:
      return {
        ...state,
        cards: state.cards.filter((card) => card.id !== action.payload.cardId)
      }

    case SURVEY_GET:
      return {
        ...state,
        survey: { ...action.payload, success: false }
      }

    case SURVEY_CANCEL:
      return {
        ...state,
        survey: { success: false }
      }

    case ANSWERS_POST:
      return {
        ...state,
        survey: { ...state.survey, success: true }
      }

    case PROMOTION_CODES_GET_REQUEST:
      return {
        ...state,
        promotionalCodesLoaded: false
      }

    case PROMOTION_CODES_GET:
      return {
        ...state,
        promoCodes: action.payload,
        promotionalCodesLoaded: true
      }

    case PROMOTION_CODE_SET:
      return {
        ...state,
        appliedPromocode: action.payload
      }

    case CURRENT_PRODUCT_SET:
      return {
        ...state,
        currentProduct: action.payload
      }

    case CALCULATOR_DATA:
      return {
        ...state,
        calculatorData: action.payload
      }

    case SET_TRANSACTION_STEP_ID:
      return {
        ...state,
        transactionStep: action.payload
      }

    case GET_TRANSACTION_SOURCE_OF_FUNDS:
      return {
        ...state,
        sourceOfFunds: action.payload
      }

    case GET_TRANSACTION_SOURCE_OF_FUNDS_CATEGORY:
      return {
        ...state,
        sourceOfFundsCategory: action.payload
      }

    case GET_TRANSACTION_PURPOSE_DICTIONARY:
      return {
        ...state,
        transactionPurposeDictionary: action.payload
      }

    default:
      return state
  }
}
