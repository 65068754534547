import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import PropTypes from 'prop-types'

const LinkRenderer = ({ children = undefined, href = undefined }) => {
  if (href.substring(0, 1) === '/') {
    return <a href={href}>{children}</a>
  }
  return (
    <a href={href} target="_blank" rel="noreferrer">
      {children}
    </a>
  )
}

const Markdown = ({ children = undefined }) => {
  if (!children) {
    return null
  }

  return (
    <ReactMarkdown components={{ a: LinkRenderer }} remarkPlugins={[remarkGfm]}>
      {children}
    </ReactMarkdown>
  )
}

LinkRenderer.propTypes = {
  href: PropTypes.string,
  children: PropTypes.instanceOf(Array)
}

Markdown.propTypes = {
  children: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string])
}

export default Markdown
