import { GET_BLOG_POSTS, GET_BLOG_POSTS_FAILURE, GET_BLOG_POSTS_REQUEST, PETCH_BLOG_POST } from '../actions/types'

const initialState = {
  blogs: [],
  blogsLoading: true
}

const replaceImage = (state, payload) => {
  const updateBlogs = state?.blogs?.map(
    (oldBlog) => (oldBlog.id === payload.id ? { ...oldBlog, image: payload?.image } : oldBlog)
  )
  return updateBlogs
}

export default (state = initialState, action = { type: undefined, payload: undefined }) => {
  switch (action.type) {
    case GET_BLOG_POSTS:
      return {
        ...state,
        blogs: action.payload,
        blogsLoading: false
      }

    case GET_BLOG_POSTS_REQUEST:
      return {
        ...state,
        blogs: action.payload,
        blogsLoading: true
      }

    case GET_BLOG_POSTS_FAILURE:
      return {
        ...state,
        blogs: action.payload,
        blogsLoading: false
      }

    case PETCH_BLOG_POST:
      return {
        ...state,
        blogs: replaceImage(state, action.payload),
        blogsLoading: false
      }

    default:
      return {
        ...state
      }
  }
}
