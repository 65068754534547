import { useEffect } from 'react'
import { cmsHeartBeatCheck } from '../../actions/cms'
import '../../styles/components/layout/CmsLoaderStyle.scss'
import MaintenanceScreen from '../MaintenanceScreen/MaintenanceScreen'

const CmsLoader = () => {
  useEffect(() => {
    const cmsInterval = setInterval(() => {
      cmsHeartBeatCheck()
    }, 10000)

    return () => {
      clearInterval(cmsInterval)
    }
  }, [])

  return (
    <MaintenanceScreen />
  )
}

export default CmsLoader
