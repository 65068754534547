import { NOTIFICATION } from '../actions/types'

const initialState = {}

export default (state = initialState, action = { type: undefined, payload: undefined }) => {
  switch (action.type) {
    case NOTIFICATION:
      return { message: action.payload?.message, link: action.payload?.link }

    default:
      return state
  }
}
