import { GET_CUSTOMER_COMPANY_INFO_SOURCES, GET_CUSTOMER_SOURCES } from '../actions/types'

const initialState = {
  contactSuccess: false
}

export default (state = initialState, action = { type: undefined, payload: undefined }) => {
  switch (action.type) {
    case GET_CUSTOMER_SOURCES:
      return {
        ...state,
        customerSources: action.payload
      }

    case GET_CUSTOMER_COMPANY_INFO_SOURCES:
      return {
        ...state,
        customerCompanyInfoSources: action.payload
      }

    default:
      return state
  }
}
