import { SET_LOCATION } from '../actions/types'

const initialState = {
  pathName: undefined,
  language: undefined,
  prevPathName: undefined
}

export default (state = initialState, action = { type: undefined, payload: undefined }) => {
  switch (action.type) {
    case SET_LOCATION:
      return {
        ...state,
        prevPathName: action.payload?.pathName === state.pathName ? state.prevPathName : state.pathName,
        ...action.payload
      }

    default:
      return state
  }
}
