import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SimpleCookieConsentModal from './Modals/CookiesConsentModals/SimpleCookieConsentModal'
import { SHOW_COOKIE_CONSENT_POP_UP } from '../actions/types'
import CookieSettingsModal from './Modals/CookiesConsentModals/CookieSettingsModal'
import areCookiesManaged from '../utils/areCookiesManaged'
import { getCookiesConsentPopUpsContent } from '../actions/cms'

const CookieConsent = () => {
  const dispatch = useDispatch()
  const showCookiesModal = useSelector((state) => state.login.showCookiePopup)
  const [showCookieSettings, setShowCookieSettings] = useState(false)
  const isCookieBannerEnabled = useSelector((state) => state.cms?.cookiesConsentPopUps?.showCookieBanners)

  useEffect(() => {
    dispatch(getCookiesConsentPopUpsContent())
    if (isCookieBannerEnabled) {
      areCookiesManaged(dispatch)
    }
  }, [dispatch, isCookieBannerEnabled])

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted')
    dispatch({ type: SHOW_COOKIE_CONSENT_POP_UP, payload: { showCookiePopup: false } })
    setShowCookieSettings(false)
  }

  const handleReject = () => {
    localStorage.setItem('cookieConsent', 'rejected')
    dispatch({ type: SHOW_COOKIE_CONSENT_POP_UP, payload: { showCookiePopup: false } })
    setShowCookieSettings(false)
  }

  const handleManage = () => {
    dispatch({ type: SHOW_COOKIE_CONSENT_POP_UP, payload: { showCookiePopup: false } })
    setShowCookieSettings(true)
  }

  return (
    <>
      {showCookiesModal && (
        <SimpleCookieConsentModal
          handleAccept={handleAccept}
          handleReject={handleReject}
          showCookiesModal={showCookiesModal}
          handleManage={handleManage}
        />
      )}
      {showCookieSettings && (
        <CookieSettingsModal
          handleAccept={handleAccept}
          handleReject={handleReject}
          handleClose={() => setShowCookieSettings(false)}
          showCookiesModal={showCookieSettings}
        />
      )}
    </>
  )
}

export default CookieConsent
